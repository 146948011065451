/* eslint-disable max-len */

import {
  AR_AGING_STATUSES,
  BTMS_FEE_TYPES,
  COMPANY_STATUSES,
  CSV_SEPERATORS,
  CUSTOMER_DIVISION,
  CUSTOMIZABLE_INVOICE_STATUSES,
  CUSTOM_INVOICE_FEE_STATUSES,
  CUSTOM_INVOICE_FEE_TYPES,
  CUSTOM_INVOICE_FEE_UNITS,
  CUSTOM_SHIPMENT_PRICING_TYPES,
  DOCUMENT_TYPES,
  DOF2_INVOICE_REJECTION_REASON,
  DOF2_REQUEST_ON_HOLD_REASON,
  DOF_REQUEST_ON_HOLD_REASON,
  DOF_REQUEST_REJECTION_REASON,
  DOF_SHIPMENT_STATUSES,
  DO_CANCELLATION_REASONS,
  DO_REJECTION_REASONS,
  DRIVER_QUEUES_STATUS,
  DRIVER_STATUSES,
  FEE_BANNER_STATUSES,
  FEE_REQUESTS,
  FEE_TYPES,
  FEE_TYPE_STATUSES,
  FILE_UPLOAD_SOURCE,
  FILE_UPLOAD_MODE,
  FIRST_MILE_USER_ACCESS_TYPE,
  GENERAL_ENTITY_STATUSES,
  GOODS_REJECTION_CATEGORY,
  INDUSTRY,
  INVOICE_PAYMENT_STATUS,
  INVOICE_STATUS,
  IS_RETURN_FEE_COVERED,
  IS_RETURN_GOODS,
  JOB_STATUSES,
  LAST_DISBURSEMENT_PPH23_DEDUCTOR,
  LOCAL_SHIPMENT_STATUSES,
  LOCATION_CHANGE_REASON,
  LOCATION_FEE_TYPE,
  LOCATION_TYPE,
  PANTHERA_PIC_DIVISION,
  PAYMENT_SUMMARIES_SECTION_STATUSES,
  PAYMENT_SUMMARIES_STATUSES,
  PAYMENT_TYPES,
  PLATE_COLORS,
  PLATE_NUMBER_HISTORIES_COLUMN,
  PODF_INVOICE_REJECTION_REASON,
  PODF_REQUEST_ON_HOLD_REASON,
  PPH23_DEDUCTOR,
  PRODUCT_CATEGORY,
  REIMBURSEMENT_REQUIREMENT_TYPE,
  ROUTE_TYPES,
  SHIPMENT_ACCEPTANCE_STATUS,
  SHIPMENT_EXPENSE_TYPES,
  SHIPMENT_INVOICE_TYPE,
  SHIPMENT_POINT_TYPES,
  SHIPMENT_STATUSES,
  SHIPMENT_TIME_ACCURACY_STATUS,
  SHIPPER_STATUSES,
  SHIPPER_TYPES,
  SHOW_CLAIMS_IN,
  SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE,
  SWIFT_PAYMENT_REQUEST_STATUSES,
  SWIFT_PAYMENT_TYPES,
  TRANSPORTER_INCENTIVE_AMOUNT_TYPE,
  TRANSPORTER_INCENTIVE_BUSINESS_LINE,
  USER_ACCESS_TYPE,
  USER_STATUSES,
  VEHICLE_AVAILABILITY_STATUS,
  VEHICLE_REPORT_TYPE,
  VENDOR_ASSIGNMENT_TYPE,
  BIDDING_HISTORY_STATUSES,
  COACH_MARK_CONTENT,
  EXPEDITION_PROVIDER,
  CANCELLATION_INVOICE_REASON,
  ONHOLD_INVOICE_REASON,
  FLEET_MONITORING_STATUS,
  GPS_STATUS,
  READINESS_SHIPMENT_STATUS,
  MONTH_INVOICE_FORMAT,
  YEAR_INVOICE_FORMAT,
  INVOICE_FORMAT_DEFAULT,
  COMPANY_PBI_VEHICLE_STATUS,
  PPN_TYPE,
  CURRENCY_CODES,
} from '~/Configurations/constants';
import { INVOICE_FORM_TYPE, SHIPMENT_SEARCH_TYPES } from '~/Modules/Payment/Invoice/Pages/InvoiceGenericCreationPage/constants';
import { DISBURSEMENT_STATUS_ENUM } from '~/Modules/Payment/Invoice/Pages/InvoiceListPage/constant';
import { DELIVERY_ORDER_SEARCH_TYPES } from '~/Modules/Payment/constants';
import { CUSTOM_FIELDS } from '~/Modules/Shipment/Utilities/constants';

export default {
  accept_bid: 'Ambil Sekarang',
  acceptance_date: 'Tanggal Penerimaan',
  account_details: 'Detail Akun',
  account_holder_name: 'Nama Pemegang Rekening',
  account_name: 'Nama Akun',
  account_number: 'Nomor Rekening',
  account_receivable: 'Akun Diterima',
  account_receiveble: 'Piutang dagang',
  action: 'Action',
  activate_driver: 'Aktifkan Supir',
  activate_truck: 'Aktifkan Unit',
  active_kir: 'KIR Aktif',
  active_lembar_pajak: 'Lembar Pajak Aktif',
  active_sim: 'SIM Aktif',
  active_stnk: 'STNK Aktif',
  active: 'Aktif',
  activity_log: 'Activity Log',
  activity_log_type: {
    comment: {
      UPDATE: '{{name}} meninggalkan komentar',
    },
    create_company_vehicles: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    save_brokerage_vendor: {
      INSERT: 'Transporter created by {{name}}',
      UPDATE: 'Basic Profile edited by {{name}}',
    },
    save_customer: {
      INSERT: '{{name}} membuat shipper',
      UPDATE: '{{name}} menyimpan shipper',
    },
    save_warehouse: {
      INSERT: '{{name}} membuat lokasi',
      UPDATE: '{{name}} menyimpan lokasi',
    },
    save_warehouse_fee: {
      INSERT: '{{name}} membuat biaya lokasi',
      UPDATE: '{{name}} menyimpan biaya lokasi',
    },
    update_partner_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    upsert_partner_company_vehicle: {
      INSERT: 'Truck created by {{name}}',
      UPDATE: 'Truck details edited by {{name}}',
      VERIFICATION: 'Truck verified by {{name}}',
    },
    verify_company_driver: {
      UPDATE: 'Driver verified by {{name}}',
      VERIFICATION: 'Driver verified by {{name}}',
    },
    verify_vendor: {
      UPDATE: 'Transporter verified by {{name}}',
    },
  },
  actual_arrival: 'Waktu Kedatangan Aktual',
  actual_cbm: 'Jumlah CBM aktual',
  actual_kg: 'Jumlah Kg aktual',
  actual_route: 'Rute Ditempuh',
  actual_utilization: 'Utilisasi Aktual',
  add_account: 'Tambah Rekening',
  add_adjustment_details: 'Tambah Detail Adjustment',
  add_attachment: 'Tambah Lampiran',
  add_bank_account: 'Tambah Rekening Bank',
  add_claims: '+ Tambahkan Klaim',
  add_column: 'Tambah Kolom',
  add_company_document: 'Tambahkan Dokumen Perusahaan',
  add_component: 'Tambah Komponen',
  add_contact: '+ Tambah kontak',
  add_contract_document: 'Tambahkan Dokumen Kontrak',
  add_costs: 'Tambah biaya',
  add_customer: 'Tambah Customer',
  add_days: 'Tambah Hari',
  add_details: 'Tambah Detail',
  add_do: 'Tambah DO',
  add_document: 'Tambah Dokumen',
  add_document_resturn_sla: 'Tambahkan SLA Pengembalian Dokumen',
  add_employee: 'Tambah Karyawan',
  add_expenses: 'Tambah Pengeluaran',
  add_fee: 'Masukan Biaya',
  add_fee_for_truck_type: 'Masukkan Biaya Tambahan {{name}}',
  add_file: 'Tambah File',
  add_income: 'Tambah Pendapatan',
  add_invoice: 'Tambah Invoice',
  add_kargo_fee_adjustment: 'Tambah Detail Kargo Fee Adjustment',
  add_location: 'Tambah Lokasi',
  add_logo: 'Tambah Logo',
  add_pic_contact: 'Tambah Kontak PIC',
  add_pod: 'Tambah POD',
  add_pph23: 'Tambah PPh23',
  add_row: 'Tambah Baris',
  add_template_name: 'Masukan Nama Template',
  add_unit: 'Tambah Unit',
  add_warehouse: 'Tambah Gudang',
  added_at: 'Ditambahkan pada',
  added_the_driver: 'Tambah Supir',
  additional_fee: 'Biaya Tambahan',
  additional_fees: 'Biaya Lain-Lain',
  additional_fee_type: {
    cancellation: {
      TRUCK_ARRIVAL: 'Pembatalan setelah truk tiba di lokasi muat',
      TRUCK_DETAILS_CONFIRMED: 'Pembatalan setelah detil truk terkonfirmasi',
    },
    multishipment: 'Biaya pengiriman lebih dari satu',
    overnight: {
      LOADING: 'Biaya inap saat muat',
      UNLOADING: 'Biaya inap saat bongkar',
    },
  },
  additional_notes: 'Catatan Tambahan',
  additional_transporter_note: 'Catatan internal',
  additional_transporter_notes: 'Catalan Tambahan Transporter',
  address: 'Alamat',
  adjustment: 'Adjustment',
  adjustment_type: 'Tipe Adjustment',
  after: 'Sesudah',
  aging: 'Aging',
  aging_date_of_submission: 'Tgl. Pengajuan',
  aging_disbursement_date: 'Tgl. Cair',
  aging_maturity: 'Aging Jatuh Tempo',
  aging_shipper_payment_date: 'Tgl. Pembayaran Shipper',
  aging_total_amount: 'Jumlah',
  agreement_status: 'Status Perjanjian',
  all_customer: 'Semua Customer',
  alternative_doc: 'Dokumen Alternatif',
  amount: 'Jumlah',
  amount_in_words: 'Terbilang',
  applied_dof2_invoice_details: 'Rincian Invoice Diajukan',
  applied_dof_shipment_list_details: 'Rincian Pengiriman yang Diajukan',
  applied_podf_invoice_details: 'Rincian Invoice Diajukan',
  apply_swift_payment: 'Ajukan Pembayaran Cepat',
  applying_for_dof_2nd_disbursement: 'Mengajukan DOF Pencairan ke-2',
  applying_for_podf: 'Mengajukan PODF',
  applying_for_swift_payment: 'Mengajukan Pembayaran Cepat',
  apply: 'Terapkan',
  approved_dof2_invoice_details: 'Rincian Invoice Disetujui',
  approved_dof_shipment_list_details: 'Rincian Pengiriman yang Disetujui',
  approved_podf_invoice_details: 'Rincian Invoice Disetujui',
  ar_aging_status_map: {
    [AR_AGING_STATUSES.DISBURSED]: 'pencairan ke 1 ditransfer',
    [AR_AGING_STATUSES.ON_HOLD]: 'Pengajuan Ditunda',
    [AR_AGING_STATUSES.REQUESTED]: 'Diajukan',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_REQUESTED]: 'pencairan ke 2 diajukan',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_APPROVED]: 'pencairan ke 2 diproses',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'pencairan ke 2 diproses',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'pencairan ke 2 ditransfer',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_REJECTED]: 'pencairan ke 2 ditolak',
    [AR_AGING_STATUSES.SECOND_DISBURSEMENT_ON_HOLD]: 'pencairan ke 2 ditunda',
    [AR_AGING_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'pencairan ke 3 diproses',
    [AR_AGING_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'pencairan ke 3 ditransfer',
  },
  armada_info: 'Informasi Armada',
  arrive_at_location: 'Tiba di Lokasi',
  ascending: 'Ascending',
  attachment: 'Lampiran',
  availability_info: 'Informasi Ketersediaan',
  available_slot: 'Slot Tersedia',
  availablity_schedule: 'Jadwal Ketersediaan',
  availability_status: 'Ketersediaan Status',
  average_load_time: 'Rata-rata Waktu Muat',
  average_unload_time: 'Rata-rata Waktu Bongkar',
  average_vehicle_load_time: 'Rata-rata Waktu Muat - Semua Jenis Armada',
  average_vehicle_unload_time: 'Rata-rata Waktu Bongkar - Semua Jenis Armada',
  back: 'Kembali',
  bank: 'Bank',
  bank_account: 'Rekening Bank',
  bank_account_number: 'Nomor Rekening',
  bank_account_owner: 'Pemilik Rekening',
  bank_code: 'Bank Code',
  bank_name: 'Nama Bank',
  bank_information: 'Informasi Bank',
  bank_information_is: 'Informasi Bank',
  before: 'Sebelum',
  bid: 'Tawaran',
  bid_accepted: 'Diterima',
  bid_deadline: 'Batas Waktu Tawaran',
  bid_faq: 'Bagaimana cara kerja penawaran?',
  bid_maximum_price: 'Tawaran maksimal adalah {{value}}',
  bid_minimum_price: 'Tawaran minimal adalah {{value}}',
  bid_maximum_slot: 'Jumlah maksimal adalah {{value}} Slot',
  bid_price: 'Harga Tawaran',
  bidding: 'Penawaran',
  bidding_active: 'Tawaran Anda',
  bidding_available: 'Penawaran Tersedia',
  bidding_price: 'Harga Penawaran',
  bidding_statuses_map: {
    [BIDDING_HISTORY_STATUSES.ACCEPTED]: 'Diterima',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_SYSTEM]: 'Ditolak',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Dibatalkan Vendor',
    [BIDDING_HISTORY_STATUSES.CANCELLED_AT_CONFIRMATION]: 'Dibatalkan Vendor',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_SHIPPER]: 'Customer Membatalkan',
    [BIDDING_HISTORY_STATUSES.CANCELLED_BY_INTERNAL]: 'Customer Mengalihkan Penugasan',
    [BIDDING_HISTORY_STATUSES.REJECTED]: 'Ditolak',
    [BIDDING_HISTORY_STATUSES.UNFULFILLED]: 'Konfirmasi Terlewat',
  },
  bidding_incentive: 'Insentif ({{percent}}%)',
  bidding_total: 'Total Tawaran',
  billing_address: 'Alamat tagihan',
  billing_transfer_account: 'Rekening Transfer Penagihan',
  birth_date: 'Tanggal Lahir',
  body_code: 'No. Lambung',
  brand_name: 'Nama merk',
  btms_upload_document: {
    add_do_number: 'Tambah Nomor Surat Jalan',
    add_shipment_quantities: 'Tambah Barang Terkirim',
    claim_included: 'Ada klaim barang',
    no_claim_included: 'Tidak ada',
  },
  bulk_upload_csv: {
    cancel: 'Batalkan',
    csv_separator: 'Pemisah CSV',
    csv_upload_shipment: 'Unggah Pengiriman dari file CSV',
  },
  bulk_upload_excel: {
    cancel: 'Batalkan',
    multiple_pickup_dropoff: 'Mutliple Pickup/Dropoff',
    single_pickup_dropoff: 'Single Pickup/Dropoff',
    upload_shipment: 'Unggah Pengiriman',
    upload_shipment_type_map: {
      [FILE_UPLOAD_MODE.SINGLE_PICKUP_DROPOFF]: 'Unggah Pengiriman Single Pickup/Dropoff',
      [FILE_UPLOAD_MODE.MULTIPLE_PICKUP_DROPOFF]: 'Unggah Pengiriman Multiple Pickup/Dropoff',
    },
  },
  calendar_days: 'Hari-hari kalender',
  cancel: 'Batal',
  cancel_it: 'Batalkan',
  cancel_bid: 'Batalkan Tawaran',
  cancel_invoice: 'Batalkan invoice',
  cancel_invoice_alert: 'Invoice ini akan dibatalkan dan tidak akan bisa diaktifkan kembali',
  cancel_reason: 'Alasan Pembatalan',
  cancel_shipment: 'Batalkan Pengiriman',
  cancellation_fee: 'Biaya pembatalan',
  cancellation_fees: 'Biaya Pembatalan',
  cancellation_invoice_reason: {
    [CANCELLATION_INVOICE_REASON.NEED_REVISION]: 'Detail pada invoice perlu perbaikan',
    [CANCELLATION_INVOICE_REASON.INCOMPLETE]: 'Lampiran belum lengkap',
    [CANCELLATION_INVOICE_REASON.OTHER_REASON]: 'Lainnya',
  },
  cancellation_reason: 'Alasan Pembatalan',
  cancelled_by_internal: 'Customer Mengalihkan Penugasan',
  cbm: 'Cbm',
  cbm_actual: 'Cbm Aktual',
  cbm_expected: 'Cbm Ekspektasi',
  central_address: 'Alamat Pusat',
  certain_products: 'Deskripsi',
  change: 'Ubah',
  change_adjustment_details: 'Ubah Detail Adjustment',
  change_customer: 'Ubah Customer',
  change_details: 'Ubah Detail',
  change_invoice: 'Ubah Invoice',
  change_invoice_status: 'Ubah Status Invoice',
  change_password: 'Ubah Password',
  change_shipment: 'Ubah Pengiriman',
  change_slot: 'Ubah Slot',
  change_status: 'Ubah Status',
  change_truck_and_driver: 'Ubah Truk & Supir',
  change_warehouse: 'Ubah Gudang',
  changes: 'Perubahan',
  changesInCustomerPricesClaimTransferFee: 'Perubahan Harga Customer - Klaim - Transfer Fee',
  chassis_number: ' Nomor Chasis',
  check_account: 'Cek Rekening',
  choose_all: 'Pilih Semua',
  choose_bank: 'Pilih bank',
  choose_company: 'Pilih Perusahaan',
  choose_customer: 'Pilih Customer',
  choose_document_to: 'Pilih sumber dokumen yang Ingin dilihat',
  choose_driver: 'Pilih Supir',
  choose_file: 'Pilih File',
  choose_language: 'Pilih bahasa',
  choose_pod: 'Pilih SJ Bongkar',
  choose_shipment: 'Pilih Pengiriman',
  choose_shipment_number: 'Pilih No. Pengiriman',
  choose_shipment_from_dof: 'Pilih Pengiriman dari DOF',
  choose_truck: 'Pilih Truk',
  choose_unit: 'Pilih Satuan',
  city: 'Kota',
  claim: 'Klaim',
  claim_value: 'Nilai Klaim',
  claim_value_collection_sla: 'Klaim Pengumpulan Nilai SLA',
  claim_terms: 'Ketentuan Klaim',
  claims: 'Klaim',
  clear: 'Hapus',
  close: 'Menutup',
  closed: 'Tutup',
  column_form_placeholder: 'Tulis Judul Kolom',
  company: 'Perusahaan',
  company_address: 'Alamat Perusahaan',
  company_code: 'Kode Perusahaan',
  company_details: 'Detail Perusahaan',
  company_pricing: {
    usage_status: 'Status Penggunaan',
    history: {
      history_title: 'Riwayat Penggunaan',
      cycle: 'Siklus',
      commitment_do: 'Komitmen DO',
      created_do: 'DO Dibuat',
      cycle_start: 'Mulai',
      cycle_end: 'Akhir',
      cycle_month: 'Bulan',
    },
    enterprise: {
      contract_period: 'Periode Kontrak',
      commitment_do: 'Komitmen DO',
      created_do: 'DO Dibuat',
    },
    non_enterprise: {
      kuota_detail: 'Rincian Total Kuota DO',
      kuota_do: 'Kuota DO',
      free_kuota: 'Kuota Gratis',
      extra_kuota: 'Kuota Tambahan',
      total_kuota: 'Total Kuota DO',
      tooltip_info: 'Total DO yang dibuat dari kuota DO bulan ini',
    },
  },
  company_document: 'Dokumen Perusahaan',
  company_document_type: 'Tipe Dokument Perusahaan',
  company_documents: 'Dokumen Perusahaan',
  company_name: 'Nama Perusahaan',
  company_number_already_exists: 'Nama Perusahaan sudah ada',
  company_settings: 'Pengaturan Perusahaan',
  company_status_map: {
    [COMPANY_STATUSES.ACTIVE]: 'Aktif',
    [COMPANY_STATUSES.CHURN]: 'Churn',
    [COMPANY_STATUSES.DEACTIVATED]: 'Dinonaktifkan',
    [COMPANY_STATUSES.INACTIVE]: 'Nonaktif',
    [COMPANY_STATUSES.REGISTERED]: 'Terdaftar',
  },
  company_title: 'Perusahaan',
  company_pbi_vehicle_status: {
    [COMPANY_PBI_VEHICLE_STATUS.UNAVAILABLE]: 'Anda tidak bisa memberi tawaran karena Anda belum menambahkan jenis truk yang diminta oleh Customer',
    [COMPANY_PBI_VEHICLE_STATUS.PENDING_VERIFICATION]: 'Anda belum bisa memberi tawaran karena unit masih dalam proses verifikasi',
  },
  complete_now: 'Lengkapi Sekarang',
  completion_date: 'Tanggal Selesai',
  confirm: 'Konfirmasi',
  confirm_fee_delete: 'Konfirmasi penghapusan biaya',
  confirm_proforma_invoice: 'Konfirmasi Proforma Invoice',
  confirm_your_availability: 'Konfirmasi ketersediaan Anda',
  confirmation: 'Konfirmasi',
  confirmation_expired: 'Konfirmasi Terlewat',
  contact: 'Kontak',
  contact_phone: 'Telepon Kontak',
  contacts: 'Kontak',
  container: 'Container',
  container_information: 'Informasi Kontainer',
  container_number: 'Nomor Kontainer',
  continue: 'Lanjut',
  contract_document_type: 'Tipe Dokument Kontrak',
  contract_documents: 'Dokumen Kontrak',
  count_included: 'Dihitung',
  create: 'Buat',
  create_component: 'Buat Komponen',
  create_do_block_dialog_text: 'Hubungi pihak Kargo Nexus for Transporters untuk meningkatkan jumlah kuota DO Anda',
  create_do_block_dialog_title: 'Jumlah DO Telah Mencapai Kuota Maksimal',
  create_do_block_grace_period_dialog_description: 'Anda tidak dapat mengakses fitur ini karena periode kontrak Anda telah habis. Hubungi kami agar tetap terhubung dengan seluruh fitur Kargo Nexus for Transporters',
  create_format: 'Buat Format',
  create_do_block_grace_period_dialog_title: 'Hubungi kami untuk menggunakan fitur ini',
  create_invoice: 'Buat Invoice',
  create_invoice_revision: 'Buat Revisi Invoice',
  create_invoice_type: {
    [SHIPMENT_INVOICE_TYPE.ONCALL]: 'Buat On Call Invoice',
    [SHIPMENT_INVOICE_TYPE.RENTAL]: 'Buat Dedicated Invoice',
  },
  create_new_customer: 'Buat Customer Baru',
  create_new_invoice: 'Buat Invoice Baru',
  create_new_shipment: 'Buat Pengiriman Baru',
  create_new_template: 'Buat Template Baru',
  create_new_shipment_template: 'Buat Template Pengiriman Baru',
  create_new_warehouse: 'Buat Gudang Baru',
  create_proforma_invoice: 'Buat Proforma Invoice',
  create_shipment: 'Buat Pengiriman',
  csv_separators: {
    [CSV_SEPERATORS.COMMA]: 'Koma ( , )',
    [CSV_SEPERATORS.COLON]: 'Titik dua ( : )',
    [CSV_SEPERATORS.SEMICOLON]: 'Titik koma ( ; )',
    [CSV_SEPERATORS.PIPE]: 'Pipa ( | )',
    [CSV_SEPERATORS.TAB]: 'Tab ( \\t )',
  },
  currency_prefix_map: {
    [CURRENCY_CODES.IDR]: 'Rp',
    [CURRENCY_CODES.MYR]: 'RM',
    [CURRENCY_CODES.SGD]: '$',
    [CURRENCY_CODES.USD]: '$',
  },
  customer_code: 'Kode Customer',
  custom_fields: {
    add_field: 'Tambah Input Lain',
    custom_field_dialog: {
      dialog_header: 'Pilih Jenis Info Tambahan di Luar Pengiriman',
      dialog_cancel: 'Batalkan',
      dialog_ok: 'Simpan',
      other_input_name_label: 'Nama Input Label',
      other_input_name_placeholder: 'Masukkan Nama Label (Maks. 50 Karakter)',
    },
    custom_field_radio_label: {
      [CUSTOM_FIELDS.EXTERNAL_ID]: 'External ID',
      [CUSTOM_FIELDS.CUSTOMER_ORDER_DATE]: 'Tanggal Pemesanan Customer',
      [CUSTOM_FIELDS.SHIPMENT_TYPE]: 'Jenis Pengiriman',
      [CUSTOM_FIELDS.INTERNAL_NOTES]: 'Catatan Internal',
      [CUSTOM_FIELDS.OTHER]: 'Lainnya',
      [CUSTOM_FIELDS.CONTAINER_NUMBER]: 'Nomor Kontainer',
      [CUSTOM_FIELDS.SHIPPER_SEAL_NUMBER]: 'Nomor Segel Pelayaran',
      [CUSTOM_FIELDS.CUSTOMER_SEAL_NUMBER]: 'Nomor Segel Customer',
      [CUSTOM_FIELDS.CHASSIS_NUMBER]: 'Nomor Chasis',
    },
  },
  custom_invoice_fee_form_map: {
    amount: {
      label: 'Nominal Biaya',
      placeholder: 'Ketik nominal biaya',
    },
    fee_type: {
      label: 'Tipe Biaya',
      placeholder: '',
    },
    notes: {
      label: 'Keterangan',
      placeholder: 'Ketik tambahan informasi terkait biaya',
    },
    PER_CBM: {
      label: 'Volume CBM (Tidak wajib)',
      placeholder: 'Ketik volume dalam meter kubik',
    },
    PER_KG: {
      label: 'Kilograms (Tidak wajib)',
      placeholder: 'Ketik bobot muatan dalam kg\'s',
    },
    total_amount: {
      label: 'Total Biaya',
      placeholder: '',
    },
  },
  custom_invoice_fee_status: {
    [CUSTOM_INVOICE_FEE_STATUSES.APPROVED]: 'Di-approve',
    [CUSTOM_INVOICE_FEE_STATUSES.NOT_INVOICED]: 'Not Invoiced',
    [CUSTOM_INVOICE_FEE_STATUSES.PAID]: 'Paid',
    [CUSTOM_INVOICE_FEE_STATUSES.PAYMENT_PENDING]: 'Invoice Terkirim',
    [CUSTOM_INVOICE_FEE_STATUSES.REJECTED]: 'Ditolak',
  },
  custom_invoice_fee_types: {
    [CUSTOM_INVOICE_FEE_TYPES.LOADING_FEE]: 'Biaya Muat',
    [CUSTOM_INVOICE_FEE_TYPES.MULTIDROPOFF_FEE]: 'Biaya Multidrop',
    [CUSTOM_INVOICE_FEE_TYPES.MULTIPICKUP_FEE]: 'Biaya Multipickup',
    [CUSTOM_INVOICE_FEE_TYPES.OTHER_FEE]: 'Biaya Lain',
    [CUSTOM_INVOICE_FEE_TYPES.OVERNIGHT_FEE]: 'Biaya Inap',
    [CUSTOM_INVOICE_FEE_TYPES.SHIPMENT_FEE]: 'Biaya Pengiriman',
    [CUSTOM_INVOICE_FEE_TYPES.TICKET_FEE]: 'Biaya Tilang',
    [CUSTOM_INVOICE_FEE_TYPES.TRUCKING_FEE]: 'Biaya Pengiriman',
    [CUSTOM_INVOICE_FEE_TYPES.UNLOADING_FEE]: 'Biaya Bongkar',
    [CUSTOM_INVOICE_FEE_TYPES.ADDITIONAL]: 'Biaya Tambahan',
    [CUSTOM_INVOICE_FEE_TYPES.OTHER_LOCATION_FEE]: 'Biaya Lain',
    [CUSTOM_INVOICE_FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'Biaya Inap',
    [CUSTOM_INVOICE_FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'Biaya Inap',

    // Will refactor this on the next sprint
    [CUSTOM_INVOICE_FEE_TYPES.shipment]: 'Biaya Pengiriman',
    [CUSTOM_INVOICE_FEE_TYPES.additional]: 'Biaya Tambahan',
    [CUSTOM_INVOICE_FEE_TYPES.unloading]: 'Biaya Bongkar',
    [CUSTOM_INVOICE_FEE_TYPES.loading]: 'Biaya Muat',
    [CUSTOM_INVOICE_FEE_TYPES.other]: 'Biaya Lain',
    [CUSTOM_INVOICE_FEE_TYPES.overnight_unloading]: 'Biaya Inap',
    [CUSTOM_INVOICE_FEE_TYPES.overnight_loading]: 'Biaya Inap',

    // First Mile Fees
    [CUSTOM_INVOICE_FEE_TYPES.storage]: 'Biaya Storage',
    [CUSTOM_INVOICE_FEE_TYPES.demurrage]: 'Biaya Demurrage',
    [CUSTOM_INVOICE_FEE_TYPES.thc_lolo]: 'THC-LOLO',
  },
  custom_invoice_fee_units: {
    [CUSTOM_INVOICE_FEE_UNITS.PER_CBM]: 'Per CBM',
    [CUSTOM_INVOICE_FEE_UNITS.PER_KG]: 'Per Kg',
    [CUSTOM_INVOICE_FEE_UNITS.PER_ROUTE]: 'Per Pengiriman',
  },
  custom_invoice_form_creation_headers: {
    bank_details: 'Tujuan Pembayaran Tagihan',
    billing_date: 'Tanggal Penagihan',
    other_details: 'Rincian Lain',
    total: 'Total Tagihan',
    total_summary: 'Ringkasan Tagihan',
  },
  custom_invoice_toast: {
    fee_create: 'Berhasil menambahkan biaya',
    fee_edit: 'Berhasil mengubah biaya',
  },
  custom_shipment_fee_units: {
    [CUSTOM_SHIPMENT_PRICING_TYPES.PER_CBM]: 'Per Cbm',
    [CUSTOM_SHIPMENT_PRICING_TYPES.PER_KG]: 'Per Kg',
    [CUSTOM_SHIPMENT_PRICING_TYPES.PER_SHIPMENT]: 'Per Pengiriman',
  },
  customer: 'Customer',
  customer_details: 'Detail Customer',
  customer_division: {
    [CUSTOMER_DIVISION.FINANCE]: 'Finance',
    [CUSTOMER_DIVISION.OPERATIONS]: 'Operations',
    [CUSTOMER_DIVISION.SALES]: 'Sales',
    [CUSTOMER_DIVISION.WAREHOUSE]: 'Warehouse',
    // Commented out for possible future use. Currently only valid for B-TMS - see constants.js
    // [CUSTOMER_DIVISION.PROCUREMENT]: 'Procurement',
    // [CUSTOMER_DIVISION.LOGISTICS]: 'Logistics',
    // [CUSTOMER_DIVISION.TREASURY]: 'Treasury',
    // [CUSTOMER_DIVISION.CLAIMS]: 'Claims',
  },
  customer_information: 'Informasi Customer',
  customer_list: 'Daftar Customer',
  customer_name: 'Nama Customer',
  customer_name_slash_company: 'Nama Customer / Perusahaan',
  customer_seal_number: 'Nomor Segel Customer',
  customer_title: 'Pelanggan',
  cv: 'CV',
  damage: 'Kerusakan',
  damage_description: 'Keterangan Kerusakan',
  damage_type: 'Tipe Kerusakan',
  date: 'Tanggal',
  date_of_submission: 'Tanggal Pengajuan',
  day: 'Hari',
  days: 'Hari',
  deactivate: 'Nonaktifkan',
  deactivate_customer: 'Nonaktifkan Customer',
  deactivate_driver: 'Nonaktifkan Supir',
  deactivate_employee: 'Nonaktifkan Karyawan',
  deactivate_truck: 'Nonaktifkan Unit',
  deactivate_warehouse: 'Nonaktifkan Gudang',
  deadline_confirmation: 'Deadline Konfirmasi',
  deadline_confirmation_tooltip_message: 'Konfirmasi ketersediaan Anda secepatnya supaya customer punya lebih banyak waktu untuk menjadwalkan pengirimannya.',
  deadline_date_and_time: 'Tenggat Tanggal Dan Waktu',
  dedicated_invoice: 'Dedicated Invoice',
  dedicated_rental_invoice: 'Dedicated/Rental Invoice',
  default_account: 'Rekening Utama',
  delay_submission: 'Tunda Pengiriman',
  delete: 'Hapus',
  deleted: 'Terhapus',
  delete_account: 'Hapus Rekening',
  delete_fee: 'Hapus Biaya',
  delete_invoice: 'Hapus Invoice',
  delete_sort: 'Hapus Urutan',
  delete_template: 'Hapus Template',
  delivery_order: 'Delivery Order',
  delivery_order_search_type: {
    [SHIPMENT_SEARCH_TYPES.DO_NUMBER]: 'No. Pengiriman',
    [SHIPMENT_SEARCH_TYPES.CONTAINER_NUMBER]: 'No. Container',
    [SHIPMENT_SEARCH_TYPES.LICENSE_PLATE]: 'No. Polisi',
  },
  department: 'Departemen',
  descending: 'Descending',
  description: 'Deskripsi',
  deselect_all: 'Deselect All',
  destination_warehouse_name: 'Tujuan',
  destination: 'Tujuan',
  detail: 'Rincian',
  detail_of_rejection: 'Penolakan Dokumen',
  detail_title: 'Detail',
  details_example: 'Contoh: “No.Referensi”, “No PO”, “attn”, dsb.',
  disbursement_date: 'Tanggal Pencairan',
  disbursement_scheme: 'Skema Pembayaran',
  disbursement_summary: 'Ringkasan Pembayaran',
  disbursement_scheme_stage_map: {
    do_stage: 'DO',
    emoney_stage: 'E-Money',
    invoice_paid_stage: 'Invoice Paid',
    invoice_sent_stage: 'Invoice Sent',
    pod_digital_stage: 'POD Digital',
    pod_physical_stage: 'POD Fisik',
    pol_stage: 'POL',
  },
  disbursement_status_enum_map: {
    [DISBURSEMENT_STATUS_ENUM.IN_PROCESS]: 'Sedang di Proses',
    [DISBURSEMENT_STATUS_ENUM.WAITING_FOR_WITHDRAWAL]: 'Masih di Kantong',
  },
  disbursed_amount: 'Jumlah Dicairkan',
  district: 'Kecamatan',
  division: 'Divisi',
  do: 'DO',
  do_cancellation_reason_map: {
    [DO_CANCELLATION_REASONS.ALLOCATION_REVISION]: 'Perubahan alokasi, perubahan shipment',
    [DO_CANCELLATION_REASONS.CANCELLED_BY_SHIPPER]: 'Customer Membatalkan Pengiriman',
    [DO_CANCELLATION_REASONS.COMPLIANCE_ISSUE]: 'Issue compliance',
    [DO_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Bencana alam',
    [DO_CANCELLATION_REASONS.FULL_STOCK]: 'Full stock at customer',
    [DO_CANCELLATION_REASONS.LOADING_DELAY]: 'Masalah proses loading di pabrik',
    [DO_CANCELLATION_REASONS.NO_EMPTY_JUGS]: 'Tidak ada galon kosong (khusus kiriman galon)',
    [DO_CANCELLATION_REASONS.NO_PALLET_JUGRACK]: 'Tidak ada pallet / jugrack tersedia',
    [DO_CANCELLATION_REASONS.NO_STOCK]: 'Tidak ada stock di pabrik / production issue',
    [DO_CANCELLATION_REASONS.OTHER_REASON]: 'Alasan lain yang tidak disebutkan diatas',
    [DO_CANCELLATION_REASONS.TKBM_ISSUE]: 'Issue TKBM',
    [DO_CANCELLATION_REASONS.TRUCK_NO_SHOW]: 'Truck tidak datang, auto expired',
    [DO_CANCELLATION_REASONS.DRIVER_ABSENCE]: 'Supir absen',
    [DO_CANCELLATION_REASONS.DRIVER_ILL]: 'Supir sakit',
    [DO_CANCELLATION_REASONS.DRIVER_NOT_VACCINATED]: 'Supir belum vaksin',
    [DO_CANCELLATION_REASONS.EXPIRED_KIR]: 'KIR armada kadaluwarsa',
    [DO_CANCELLATION_REASONS.EXPIRED_SIM]: 'SIM supir kadaluwarsa',
    [DO_CANCELLATION_REASONS.EXPIRED_STNK]: 'STNK armada kadaluwarsa',
    [DO_CANCELLATION_REASONS.VEHICLE_ISSUE]: 'Armada bermasalah/rusak',
    [DO_CANCELLATION_REASONS.VEHICLE_TAX_NOT_PAID]: 'Belum bayar pajak armada',
    [DO_CANCELLATION_REASONS.WRONG_BID_INPUT]: 'Saya memasukkan bid yang salah',
    [DO_CANCELLATION_REASONS.CUSTOMER_ORDER_ABORTED]: 'Pelanggan sudah tidak ingin mengirim lagi',
    [DO_CANCELLATION_REASONS.MOVED_TO_ANOTHER_PLATFORM]: 'Pengiriman menggunakan aplikasi lain',
  },
  do_creation_date: 'Tanggal Pembuatan DO',
  do_date: 'Tanggal DO',
  do_estimated_price: 'Estimated Posted Price',
  do_inactive_reason_map: {
    [DO_CANCELLATION_REASONS.ALLOCATION_REVISION]: 'Perubahan alokasi, perubahan shipment',
    [DO_CANCELLATION_REASONS.COMPLIANCE_ISSUE]: 'Issue compliance',
    [DO_CANCELLATION_REASONS.FORCE_MAJEURE]: 'Bencana alam',
    [DO_CANCELLATION_REASONS.FULL_STOCK]: 'Full stock at customer',
    [DO_CANCELLATION_REASONS.LOADING_DELAY]: 'Masalah proses loading di pabrik',
    [DO_CANCELLATION_REASONS.NO_EMPTY_JUGS]: 'Tidak ada galon kosong (khusus kiriman galon)',
    [DO_CANCELLATION_REASONS.NO_PALLET_JUGRACK]: 'Tidak ada pallet / jugrack tersedia',
    [DO_CANCELLATION_REASONS.NO_STOCK]: 'Tidak ada stock di pabrik / production issue',
    [DO_CANCELLATION_REASONS.OTHER_REASON]: 'Alasan lain yang tidak disebutkan diatas',
    [DO_CANCELLATION_REASONS.TKBM_ISSUE]: 'Issue TKBM',
    [DO_CANCELLATION_REASONS.TRUCK_NO_SHOW]: 'Truck tidak datang, auto expired',
    [DO_REJECTION_REASONS.DRIVER_NOT_READY]: 'Supir belum ready',
    [DO_REJECTION_REASONS.OTHER_REASON]: 'Alasan Lainnya',
    [DO_REJECTION_REASONS.VEHICLE_MAINTENANCE_NEEDED]: 'Unit truk butuh perbaikan',
    [DO_REJECTION_REASONS.VEHICLE_NOT_READY]: 'Unit truk belum ready',
  },
  do_margin: 'Margin',
  do_max_bid_limit: 'Max Bid Limit',
  do_number: 'Nomor DO',
  do_rejection_reason_map: {
    [DO_REJECTION_REASONS.DRIVER_NOT_READY]: 'Supir belum ready',
    [DO_REJECTION_REASONS.OTHER_REASON]: 'Alasan Lainnya',
    [DO_REJECTION_REASONS.VEHICLE_MAINTENANCE_NEEDED]: 'Unit truk butuh perbaikan',
    [DO_REJECTION_REASONS.VEHICLE_NOT_READY]: 'Unit truk belum ready',
  },
  do_total: 'Total DO',
  do_type: 'Tipe DO',
  do_warning: 'Membatalkan pengiriman ini setelah konfirmasi akan mempengaruhi peluang Anda untuk mendapatkan pengiriman selanjutnya.',
  document: 'Dokumen',
  document_expiry_date: '{{name}} Valid Hingga',
  document_name: 'Nama Dokumen',
  document_number: 'Nomor {{name}}',
  document_returns: 'Pengembalian Dokumen',
  document_section: {
    delete_dialog_label: 'Apakah Anda yakin untuk menghapus dokumen ini?',
    delete_dialog_title: 'Menghapus Dokumen?',
    upload_date: 'Tanggal Unggah',
    upload_document_title: 'Unggah Surat Jalan',
  },
  document_type: 'Jenis Dokumen',
  document_type_map: {
    [DOCUMENT_TYPES.CLAIMS_AND_RETURNS]: 'Klaim dan Pengembalian',
    [DOCUMENT_TYPES.CLAIM_AND_RETURN_DOCUMENT]: 'Klaim dan Pengembalian',
    [DOCUMENT_TYPES.CLAIM_AND_RETURN_GOODS]: 'Klaim dan Pengembalian',
    [DOCUMENT_TYPES.DRIVER_REJECTED_GOODS]: 'Barang Tolakan',
    [DOCUMENT_TYPES.DRIVER_REJECTION_LETTER]: 'Berita Acara Tolakan',
    [DOCUMENT_TYPES.POD]: 'SJ Bongkar',
    [DOCUMENT_TYPES.POL]: 'SJ Muat',
    [DOCUMENT_TYPES.OTHER_DOCUMENT]: 'Dokumen Lain',
  },
  documents: 'Dokumen',
  document_not_uploaded: 'Dokumen belum diunggah',
  dof: 'DOF',
  dof1_details: {
    view_pod: 'Lihat SJ Bongkar',
    view_pol: 'Lihat SJ Muat',
  },
  dof2_invoice_detail: 'Rincian Invoice',
  dof2_invoice_receipt_rejection_reason_map: {
    [DOF2_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Tanda Terima Invoice Buram',
    [DOF2_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Nomor Invoice tidak sesuai dengan Tanda Terima',
    [DOF2_INVOICE_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  dof2_invoice_rejection_reason_map: {
    [PODF_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Invoice Buram',
    [PODF_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'No. Invoice tidak ada di tanda terima Invoice',
    [PODF_INVOICE_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  dof2_podf_details: {
    upload_pod: 'Unggah SJ Bongkar',
    view_pod: 'Lihat Surat Jalan',
  },
  dof2_podf_error_toast: {
    trucking_fee: 'Salah satu DO tidak memiliki Biaya Trucking',
    upload_pod: 'Anda perlu unggah SJ Bongkar untuk melanjutkan',
  },
  dof2_request_on_hold_reason: {
    [DOF2_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]: 'Revisi pengajuan berdasarkan alasan yang diberikan untuk memproses pencairan',
    [DOF2_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Pengajuan Melebihi batas peminjaman uang',
    [DOF2_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Masalah Pembayaran terlambat',
    [DOF2_REQUEST_ON_HOLD_REASON.OTHER]: 'Lainnya',
  },
  dof2_request_status_map: {
    [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'Pencairan ke-2 Diproses',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'Pencairan ke-2 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'Pencairan ke-2 diajukan sebagian',
    [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'Pengajuan Ditunda',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'Ditolak',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'Pencairan ke-2 Diajukan',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'Pencairan ke-3 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'Pencairan ke-3 Diproses',
  },
  dof_2nd_disbursement: 'DOF Pencairan ke-2',
  dof_2nd_disbursement_summary: 'DOF pada Pencairan Kedua',
  dof_actual_top: 'DF1 Actual ToP',
  dof_at_first_disbursement: 'DOF pada Pencairan Pertama',
  dof_request_number: 'DOF1 Request Number',
  dof_request_on_hold_reason: {
    [DOF_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]: 'Revisi pengajuan berdasarkan alasan yang diberikan untuk memproses pencairan',
    [DOF_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Pengajuan Melebihi batas peminjaman uang',
    [DOF_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Masalah Pembayaran terlambat',
    [DOF_REQUEST_ON_HOLD_REASON.OTHER]: 'Lainnya',
  },
  dof_request_rejection_reason: {
    [DOF_REQUEST_REJECTION_REASON.OTHER]: 'Lainnya',
    [DOF_REQUEST_REJECTION_REASON.POD_BLUR]: 'SJ Bongkar buram',
    [DOF_REQUEST_REJECTION_REASON.POD_SHIPMENT_NUMBER_MISMATCH]: 'No. Pengiriman di SJ Bongkar tidak sesuai dengan No. Pengiriman di Nexus for Transporters',
    [DOF_REQUEST_REJECTION_REASON.POL_BLUR]: 'SJ Muat buram',
    [DOF_REQUEST_REJECTION_REASON.POL_SHIPMENT_NUMBER_MISMATCH]: 'No. Pengiriman di SJ Muat tidak sesuai dengan No. Pengiriman di Nexus for Transporters',
  },
  dof_shipment_statuses: {
    [DOF_SHIPMENT_STATUSES.POD_ACCEPTED]: 'SJ Bongkar Diterima',
    [DOF_SHIPMENT_STATUSES.POD_REJECTED]: 'SJ Bongkar Pernah Ditolak',
    [DOF_SHIPMENT_STATUSES.POL_ACCEPTED]: 'SJ Muat Diterima',
    [DOF_SHIPMENT_STATUSES.POL_PENDING]: 'Menunggu SJ Muat',
    [DOF_SHIPMENT_STATUSES.POL_REJECTED]: 'SJ Muat Pernah Ditolak',
  },
  done: 'Selesai',
  download: 'Unduh',
  download_csv: 'Unduh CSV',
  download_documents: 'Unduh Dokumen',
  download_documents_from_external: 'Unduh Dari Web',
  download_table: 'Unduh Tabel',
  download_tax_invoice_file: 'Unduh Faktur Pajak',
  download_template: 'Unduh Template',
  dp: 'DP',
  driver: 'Supir',
  driver_app: 'Aplikasi Pengemudi',
  driver_information: 'Informasi Supir',
  driver_is_in_queue: 'Supir ini sudah dialokasikan ke beberapa pengiriman lain dibawah ini:',
  driver_is_not_in_queue: 'Supir ini belum dialokasikan kepada pengiriman apa pun',
  driver_name: 'Nama Supir',
  driver_not_found: 'Supir tidak ditemukan',
  driver_notes: 'Catatan untuk Supir',
  driver_pbi_do_not_found_link: 'Lengkapi data supir ',
  driver_pbi_do_not_found_text: 'terlebih dahulu untuk menjalankan pengiriman dari Pathera Biru Indonesia',
  driver_phone: 'Telepon Supir',
  driver_phone_no: 'Nomor Telepon Supir',
  driver_phone_number: 'Telepon Supir',
  driver_safety_req: 'Ketentuan Keamanan Pengemudi',
  driver_status_map: {
    [DRIVER_STATUSES.ACTIVE]: 'Aktif',
    [DRIVER_STATUSES.CREATED]: 'Dokumen Belum Lengkap',
    [DRIVER_STATUSES.DEACTIVATED]: 'Dinonaktifkan',
    [DRIVER_STATUSES.DOCUMENT_EXPIRED]: 'SIM Kadaluarsa',
    [DRIVER_STATUSES.INACTIVE]: 'Nonaktif',
    [DRIVER_STATUSES.REGISTERED]: 'Terdaftar',
    [DRIVER_STATUSES.SUSPENDED]: 'Terblokir',
  },
  driver_queues_column_customer: 'Customer',
  driver_queues_column_delivery_number: 'No. Pengiriman',
  driver_queues_column_loading_date: 'Jadwal Muat',
  driver_queues_column_queue: 'Urutan',
  driver_queues_column_route: 'Rute',
  driver_queues_status: {
    [DRIVER_QUEUES_STATUS.ACTIVE]: 'Aktif di Aplikasi Driver',
  },
  driver_queues_title: 'Antrean Pengiriman',
  driver_requirements: 'Persyaratan supir',
  drivers: 'Supir',
  dropoff: 'Bongkar',
  due_date: 'Tanggal Jatuh Tempo',
  edit: 'Edit',
  edit_customer: 'Ubah Customer',
  edit_driver: 'Ubah Supir',
  edit_employee: 'Ubah Karyawan',
  edit_expenses: 'Ubah Pengeluaran',
  edit_fee: 'Ubah Biaya',
  edit_invoice: 'Ubah Invoice',
  edit_invoice_receipt: 'Ubah Tanda Terima Invoice',
  edit_shipment_template: 'Edit Template Pengiriman',
  edit_unit: 'Ubah Unit',
  edit_warehouse: 'Ubah Gudang',
  email: 'Email',
  email_proforma_invoice: 'Email Proforma Invoice',
  employee_details: 'Detail Karyawan',
  employees: 'Karyawan',
  end: 'Akhir',
  end_date: 'Tanggal Berakhir',
  end_time: 'Waktu Berakhir',
  enter_additional_notes: 'Tulis Keterangan',
  enter_bank_account: 'Masuk Rekening',
  enter_expense_amount: 'Tulis Jumlah Pengeluaran',
  estimated: 'Estimated',
  eta: 'Perkiraan Tiba',
  expected_cbm: 'Jumlah CBM ekspektasi',
  expected_kg: 'Jumlah Kg ekspektasi',
  expedition: 'Kurir',
  expedition_placeholder: 'Pilih Kurir',
  expedition_provider: {
    [EXPEDITION_PROVIDER.GO_SEND]: 'Go Send',
    [EXPEDITION_PROVIDER.GRAB_EXPRESS]: 'GrabExpress',
    [EXPEDITION_PROVIDER.NINJA_XPRESS]: 'Ninja Xpress',
    [EXPEDITION_PROVIDER.ANTERAJA]: 'AnterAja',
    [EXPEDITION_PROVIDER.REX_KIRIMAN_EXPRESS]: 'Rex Kiriman Express',
    [EXPEDITION_PROVIDER.LION_PARCEL]: 'Lion Parcel',
    [EXPEDITION_PROVIDER.PAXEL]: 'Paxel',
    [EXPEDITION_PROVIDER.JNE]: 'JNE',
    [EXPEDITION_PROVIDER.TIKI]: 'TIKI',
    [EXPEDITION_PROVIDER.POS_INDONESIA]: 'Pos Indonesia',
    [EXPEDITION_PROVIDER.WAHANA]: ' Wahana',
    [EXPEDITION_PROVIDER.SICEPAT]: 'SiCepat',
    [EXPEDITION_PROVIDER.SICEPAT_SIUNTUNG]: 'SiCepat SiUntung',
    [EXPEDITION_PROVIDER.JNT_EXPRESS]: 'J&T EXPRESS',
    [EXPEDITION_PROVIDER.OTHER]: 'Lainnya',
  },
  expense_type: 'Tipe Pengeluaran',
  expenses: 'Pengeluaran',
  external_id: 'Eksternal ID',
  external_id_label: 'Eksternal ID',
  fast_funding: 'Pendanaan Cepat',
  fast_payment: 'Pembayaran Cepat',
  fast_payment_request: 'Pengajuan Pembayaran Cepat',
  fee: 'Biaya',
  fee_banner_subtitle_map: {
    default: 'Semua pendapatan akan diperbaharui secara otomatis.',
    partially_disbursed: 'Menunggu konfirmasi dari Kargo untuk pembayaran yang tersisa.',
  },
  fee_banner_title_map: {
    [FEE_BANNER_STATUSES.CANCELLED]: 'Otomasi info pencairan dari Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.DISBURSED]: 'Otomasi info pencairan dari Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.REVIEW_IN_PROCESS]: 'Otomasi info pencairan dari Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.REVIEW_SHIPMENT_FEE]: 'Otomasi info pencairan dari Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.ON_HOLD]: 'Pencairan Ditunda',
    [FEE_BANNER_STATUSES.PARTIALLY_DISBURSED]: 'Pencairan Lunas Sebagian',
    [FEE_BANNER_STATUSES.REJECTED]: 'Pembayaran ditolak untuk pengiriman ini',
    [FEE_BANNER_STATUSES.REQUEST_APPROVED]: 'Otomasi info pencairan dari Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.WAITING_FOR_CLAIM]: 'Pencairan Ditunda karena Klaim',
    [FEE_BANNER_STATUSES.WAITING_FOR_CONFIRMATION]: 'Otomasi info pencairan dari Panthera Biru Indonesia',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE]: 'Invoice belum dikirim',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'Menunggu Revisi Invoice',
    [FEE_BANNER_STATUSES.WAITING_FOR_OVERNIGHT]: 'Pencairan Biaya Inap ditunda',
    [FEE_BANNER_STATUSES.WAITING_FOR_PENALTY]: 'Pencairan ditunda mengikuti aturan pinalti',
    [FEE_BANNER_STATUSES.WAITING_FOR_TKBM]: 'Pencairan TKBM ditunda',
  },
  fee_calculation: 'Kalkulasi Biaya',
  fee_changes: 'Perubahan Harga',
  fee_delete_message: 'Anda yakin ingin menghapus biayanya?',
  fee_description: 'Ketik deskripsi biaya di sini',
  fee_map: {
    [FEE_BANNER_STATUSES.CANCELLED]: '-',
    [FEE_BANNER_STATUSES.DISBURSED]: 'Lunas',
    [FEE_BANNER_STATUSES.REVIEW_IN_PROCESS]: 'Diproses - Review',
    [FEE_BANNER_STATUSES.REVIEW_SHIPMENT_FEE]: 'Diproses - Review',
    [FEE_BANNER_STATUSES.ON_HOLD]: 'Ditunda',
    [FEE_BANNER_STATUSES.PARTIALLY_DISBURSED]: 'Lunas Sebagian',
    [FEE_BANNER_STATUSES.REJECTED]: 'Ditolak',
    [FEE_BANNER_STATUSES.REQUEST_APPROVED]: 'Diproses - Pencairan',
    [FEE_BANNER_STATUSES.WAITING_FOR_CLAIM]: 'Menunggu Klaim',
    [FEE_BANNER_STATUSES.WAITING_FOR_CONFIRMATION]: 'Proses - Konfirmasi',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE]: 'Belum Ditagih',
    [FEE_BANNER_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'Menunggu Invoice Revisi',
    [FEE_BANNER_STATUSES.WAITING_FOR_OVERNIGHT]: 'Menunggu Biaya Inap',
    [FEE_BANNER_STATUSES.WAITING_FOR_PENALTY]: 'Menunggu Pinalti',
    [FEE_BANNER_STATUSES.WAITING_FOR_TKBM]: 'Menunggu TKBM',
  },
  fee_name: 'Nama Fee',
  fee_per_point_different_city: 'Biaya per Titik Antar Kota',
  fee_per_point_within_city: 'Biaya per Titik Dalam Kota',
  fee_type: 'Jenis Biaya',
  fee_type_shipper: 'Jenis Biaya Tambahan',
  fee_request_status_map: {
    [FEE_TYPE_STATUSES.CANCELLED]: 'Cancelled',
    [FEE_TYPE_STATUSES.DISBURSED]: 'Lunas',
    [FEE_TYPE_STATUSES.FEE_REJECTED]: 'Ditolak',
    [FEE_TYPE_STATUSES.REVIEW_IN_PROCESS]: 'Proses Review',
    [FEE_TYPE_STATUSES.REVIEW_SHIPMENT_FEE]: 'Proses Review',
    [FEE_TYPE_STATUSES.ON_HOLD]: 'Ditunda',
    [FEE_TYPE_STATUSES.PENDING]: 'Proses Konfirmasi',
    [FEE_TYPE_STATUSES.REJECTED]: 'Ditolak',
    [FEE_TYPE_STATUSES.REQUEST_APPROVED]: 'Proses Konfirmasi',
    [FEE_TYPE_STATUSES.REQUEST_SENT]: 'Proses Review',
    [FEE_TYPE_STATUSES.WAITING_FOR_CLAIM]: 'Ditunda',
    [FEE_TYPE_STATUSES.WAITING_FOR_CONFIRMATION]: 'Ditunda',
    [FEE_TYPE_STATUSES.WAITING_FOR_INVOICE]: 'Ditunda',
    [FEE_TYPE_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'Ditunda',
    [FEE_TYPE_STATUSES.WAITING_FOR_OVERNIGHT]: 'Ditunda',
    [FEE_TYPE_STATUSES.WAITING_FOR_PENALTY]: 'Ditunda',
    [FEE_TYPE_STATUSES.WAITING_FOR_TKBM]: 'Ditunda',
  },
  fee_request_type_map: {
    [FEE_REQUESTS.ADDITIONAL]: 'Biaya Tambahan',
    [FEE_REQUESTS.ADJUSTMENT]: 'Penyesuaian Harga',
    [FEE_REQUESTS.CLAIMS]: 'Klaims',
    [FEE_REQUESTS.INSURANCE]: 'Asuransi',
    [FEE_REQUESTS.KARGO]: 'Biaya Kargo',
    [FEE_REQUESTS.LATE]: 'Biaya Telat',
    [FEE_REQUESTS.LOADING]: 'Biaya Muat',
    [FEE_REQUESTS.OTHER]: 'Lain-lain',
    [FEE_REQUESTS.OTHER_ADJUSTMENT]: 'Penyesuaian Lain-lain',
    [FEE_REQUESTS.OVERNIGHT_LOADING]: 'Biaya Inap Muat',
    [FEE_REQUESTS.OVERNIGHT_UNLOADING]: 'Biaya Inap Bongkar',
    [FEE_REQUESTS.PENALTY]: 'Penalti',
    [FEE_REQUESTS.RETURNS]: 'Pengembalian Barang',
    [FEE_REQUESTS.SHIPMENT]: 'Biaya Pengiriman',
    [FEE_REQUESTS.TICKET]: 'Biaya Tilang Tiket',
    [FEE_REQUESTS.UNLOADING]: 'Biaya Bongkar',
  },
  fees: 'Biaya',
  fees_payload_details: 'Rincian Muatan',
  fees_required_on_truck_requirement: 'Masukan minimal satu jenis biaya overnight, satu jenis biaya cancellation, dan semua biaya multiple shipment',
  fee_types: {
    [FEE_TYPES.SHIPMENT_FEE]: 'Biaya pengiriman',
    [FEE_TYPES.LOADING_FEE]: 'Biaya muat',
    [FEE_TYPES.UNLOADING_FEE]: 'Biaya bongkar',
    [FEE_TYPES.OVERNIGHT_LOADING_FEE]: 'Biaya inap muat',
    [FEE_TYPES.OVERNIGHT_UNLOADING_FEE]: 'Biaya inap bongkar',
    [FEE_TYPES.CANCELLATION_FEE]: 'Biaya pembatalan',
    [FEE_TYPES.MULTIPICKUP_FEE]: 'Biaya multipickup',
    [FEE_TYPES.MULTIDROPOFF_FEE]: 'Multidropoff Fee',
    [FEE_TYPES.OTHER_FEE]: 'Biaya lain-lain',
    [FEE_TYPES.ADDITIONAL_FEE]: 'Biaya tambahan',
    [FEE_TYPES.OTHER_LOCATION_FEE]: 'Biaya lokasi lain-lain',
  },
  file_not_selected_yet: 'File belum dipilih',
  file_upload_source_map: {
    [FILE_UPLOAD_SOURCE.CA]: 'Aplikasi Canvasser',
    [FILE_UPLOAD_SOURCE.DA]: 'Aplikasi Mitra',
    [FILE_UPLOAD_SOURCE.TA]: 'Aplikasi Mitra',
    [FILE_UPLOAD_SOURCE.TMS]: 'Nexus for Transporters',
    [FILE_UPLOAD_SOURCE.NFT]: 'Nexus for Transporters',
    [FILE_UPLOAD_SOURCE.BTMS]: 'Panthera Biru Indonesia',
  },
  file_upload_source_tlo_map: {
    [FILE_UPLOAD_SOURCE.CA]: 'Aplikasi Canvasser',
    [FILE_UPLOAD_SOURCE.DA]: 'Aplikasi Driver',
    [FILE_UPLOAD_SOURCE.TA]: 'Aplikasi Driver',
    [FILE_UPLOAD_SOURCE.TMS]: 'Aplikasi Partner',
    [FILE_UPLOAD_SOURCE.NFT]: 'Aplikasi Partner',
    [FILE_UPLOAD_SOURCE.BTMS]: 'Internal Lorry',
  },
  fill_bidding_price: 'Isi harga tawaran',
  fill_disbursement_details: 'Isi Detail Pencairan',
  fill_truck_details: 'Isi Detail Truk',
  fill_invoice_detail: 'Isi Detail Invoice',
  final_invoice: 'Invoice Final',
  final_score_top: 'Nilai Akhir & TOP',
  finance_report: 'Laporan Keuangan',
  finance: 'Keuangan',
  find_another_payload: 'Cari muatan lain',
  finish_load: 'Selesai Muat',
  finish_load_and_unload: 'Selesai Bongkar dan Muat',
  finish_unload: 'Selesai Bongkar',
  first: 'Pencairan',
  first_disbursement: 'Pencairan Pertama',
  first_disbursement_date: 'Tanggal Pencairan Pertama',
  fixed_amount: 'Jumlah Tetap',
  fixed: 'Tetap',
  flat_pricing_type: 'Flat',
  for_receiver_at_destination: 'Untuk Penerima di Tujuan',
  forgot_password: 'Lupa Password',
  format: 'Format',
  format_reset: 'Reset Format',
  from: 'Dari',
  from_kargo_fee: 'dari Kargo Fee',
  from_kargo_nexus_for_transporters: 'dari Kargo Nexus for Transporters',
  fulfillment_type: {
    [VENDOR_ASSIGNMENT_TYPE.BROADCAST_CONTRACTED]: 'Blast',
    [VENDOR_ASSIGNMENT_TYPE.SINGLE_CONTRACTED]: 'Single',
  },
  full_address: 'Alamat lengkap',
  gdl: 'GDL',
  general_entity_status_map: {
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Nonaktif',
    [GENERAL_ENTITY_STATUSES.REGISTERED]: 'Aktif',
  },
  get_incentive: 'Dapatkan insentif {{value}}%',
  good: 'Baik',
  goods_capacity: 'Kapasitas Barang',
  goods_near_expiry_date: 'Barang Hampir Kadaluarsa',
  goods_rejection_category_map: {
    [GOODS_REJECTION_CATEGORY.CLAIM]: 'Klaim',
    [GOODS_REJECTION_CATEGORY.NO_CLAIM]: 'Tanpa Klaim',
    [GOODS_REJECTION_CATEGORY.REPACKING]: 'Kemasan Ulang',
  },
  goods_too_few: 'Barang Kurang',
  goods_too_many: 'Barang Terlalu Banyak',
  goods_type: 'Jenis Barang',
  gps_status_map: {
    [GPS_STATUS.ONLINE]: 'GPS Aktif',
    [GPS_STATUS.OFFLINE]: 'GPS Tidak Aktif',
    [GPS_STATUS.IDLE]: 'GPS Berhenti',
  },
  gross_profit: 'Gross Profit',
  group_load_id: 'Group Load ID',
  group_load_number: 'Group Load Number',
  help: 'Bantuan',
  here: 'Disini',
  hide: 'Sembunyikan',
  hide_notes: 'Sembunyikan Catatan',
  history: 'Riwayat',
  hold_reason: 'Alasan Penundaan',
  hold_request: 'Tunda Pengajuan',
  hour: 'Jam',
  hours: 'Jam',
  id_card: 'KTP',
  idr: 'Rp',
  immediately_confirm: 'Segera konfirmasi',
  inactive: 'Tidak Aktif',
  info: 'Informasi',
  instruction: 'Instruksi',
  incentives: 'Insentif',
  included: 'Dihitung',
  include_in_calculation: 'Masukkan dalam perhitungan',
  include_in_calculation_options: [
    {
      label: 'Hari Minggu',
      value: 'Hari Minggu',
    },
    {
      label: 'Hari Libur Nasional',
      value: 'Hari Libur Nasional',
    },
    {
      label: 'Hari Minggu',
      value: 'Hari Minggu',
    },
    {
      label: 'Hari Libur Nasional',
      value: 'Hari Libur Nasional',
    },
  ],
  income: 'Pendapatan',
  income_detail: 'Rincian Pendapatan',
  income_statement: 'Laba Rugi',
  industry: 'Industri',
  industry_map: {
    [INDUSTRY.BROKER_TRANSPORTER]: 'Broker / Transporter',
    [INDUSTRY.COMMODITY_AND_TRADING]: 'Komoditi dan Perdagangan',
    [INDUSTRY.E_COMMERCE]: 'E-Commerce',
    [INDUSTRY.FMCG]: 'FMCG',
    [INDUSTRY.LOGISTIC_COMPANY]: 'Perusahaan Logistik',
    [INDUSTRY.RAW_MATERIAL_OR_ORM]: 'Bahan Baku / OEM',
  },
  initial_price: 'Harga Awal',
  input: 'Input',
  input_payment_info: 'Masukkan Info Pembayaran',
  inserted_at: 'Tanggal Dibuat',
  internal_note: 'Catatan internal',
  internal_note_label: 'Catatan ini bisa dilihat driver di aplikasi Kargo Mitra',
  into_to_the_pocket: 'Masuk Kantong',
  invoice: 'Invoice',
  invoice_addressed_to: 'Invoice ditujukan kepada',
  invoice_amount_accepted: 'Jumlah Invoice Disetujui',
  invoice_amount_submitted: 'Jumlah Invoice Diajukan',
  invoice_code: 'Kode Faktur',
  invoice_code_new: 'Kode Invoice',
  invoice_create_success_message: 'Invoice berhasil dibuat!',
  invoice_revision_success_message: 'Invoice berhasil direvisi!',
  invoice_date: 'Tanggal Invoice',
  invoice_detail: 'Rincian Tagihan',
  invoice_details: 'Detail Invoice',
  invoice_email_body_placeholder: 'Ketik pesan kepada penerima invoice. Contoh: “Berikut invoice untuk pengiriman DO-123. Terima kasih telah menggunakan jasa kami.”',
  invoice_format_translation: {
    [INVOICE_FORMAT_DEFAULT['/']]: '/',
    [INVOICE_FORMAT_DEFAULT['-']]: '-',
    [INVOICE_FORMAT_DEFAULT[':']]: ':',
    [INVOICE_FORMAT_DEFAULT.MONTH_CODE]: 'Bulan',
    [INVOICE_FORMAT_DEFAULT.YEAR_CODE]: 'Tahun',
    [INVOICE_FORMAT_DEFAULT.CUSTOMER_CODE]: 'Kode Customer',
    [INVOICE_FORMAT_DEFAULT.INVOICE_CODE]: 'Kode Invoice',
    [INVOICE_FORMAT_DEFAULT.COMPANY_CODE]: 'Kode Perusahaan',
    [MONTH_INVOICE_FORMAT.NUMBER]: 'Angka (1, 2, 3, 4)',
    [MONTH_INVOICE_FORMAT.ROMAN]: 'Romawi (I, II, III, IV)',
    [MONTH_INVOICE_FORMAT.LETTER]: 'Huruf (Jan, Feb, Mar, Apr)',
    [YEAR_INVOICE_FORMAT.COMPLETE]: 'Lengkap (2020, 2021, 2022)',
    [YEAR_INVOICE_FORMAT.LAST_TWO_NUMBER]: 'Dua angka terakhir (20, 21, 22)',
  },
  invoice_form_type_map: {
    [INVOICE_FORM_TYPE.CREATE]: 'Buat',
    [INVOICE_FORM_TYPE.EDIT]: 'Edit',
    [INVOICE_FORM_TYPE.REVISE]: 'Revisi',
  },
  invoice_no: 'No. Invoice',
  invoice_number: 'Nomor Invoice',
  invoice_number_format: 'Format Nomor Invoice',
  invoice_number_format_example: 'Contoh Format Nomor Invoice',
  invoice_payment_status_filter_map: {
    [INVOICE_PAYMENT_STATUS.PAID]: 'Invoice Lunas',
    [INVOICE_PAYMENT_STATUS.CREATED]: 'Belum Lunas',
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Fisik Siap Dikirim',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Perlu Revisi',
  },
  invoice_payment_status_map: {
    [INVOICE_PAYMENT_STATUS.CREATED]: 'Invoice Dibuat',
    [INVOICE_PAYMENT_STATUS.SUBMITTED]: 'Invoice Digital Ditinjau',
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Invoice Digital Disetujui',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Perlu Revisi',
    [INVOICE_PAYMENT_STATUS.REVISION_CREATED]: 'Revisi Dibuat',
    [INVOICE_PAYMENT_STATUS.REVISION_SUBMITTED]: 'Revisi Sedang Ditinjau',
    [INVOICE_PAYMENT_STATUS.PHYSICALLY_SENT]: 'Invoice Fisik Dikirim',
    [INVOICE_PAYMENT_STATUS.PHYSICALLY_RECEIVED]: 'Invoice Fisik Ditinjau',
    [INVOICE_PAYMENT_STATUS.PHYSICALLY_APPROVED]: 'Invoice Fisik Disetujui',
    [INVOICE_PAYMENT_STATUS.ON_HOLD]: 'Invoice ditahan',
    [INVOICE_PAYMENT_STATUS.CANCELLED]: 'Dibatalkan',
    [INVOICE_PAYMENT_STATUS.PAYMENT_REQUESTED]: 'Pembayaran Diproses',
    [INVOICE_PAYMENT_STATUS.PAID]: 'Lunas',
  },
  invoice_receipt: 'Tanda Terima Invoice',
  invoice_receipt_deadline: 'Batas Waktu Penerimaan Faktur',
  invoice_receipt_schedule: 'Jadwal Penerimaan Faktur',
  invoice_sent_date: 'Tanggal Invoice',
  invoice_setup_format: 'Pengaturan Format Invoice',
  invoice_status_map: {
    [CUSTOMIZABLE_INVOICE_STATUSES.NOT_INVOICED]: 'Bisa Diinvoice',
    [CUSTOMIZABLE_INVOICE_STATUSES.PARTIALLY_INVOICED]: 'Ditagih Sebagian',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_CLAIM_APPROVAL]: 'Claim Sedang Diproses',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_POD]: 'POD Belum Diterima',
    [CUSTOMIZABLE_INVOICE_STATUSES.ONGOING_SHIPMENT]: 'Pengiriman Masih Berjalan',
    [CUSTOMIZABLE_INVOICE_STATUSES.WAITING_FOR_INVOICE_PAID]: 'Menunggu Pembayaran Shipper',
    [CUSTOMIZABLE_INVOICE_STATUSES.EXPIRED_STNK]: 'STNK Kadaluarsa',
    [INVOICE_STATUS.NOT_INVOICED_YET]: 'Belum di-Invoice',
    [INVOICE_STATUS.PAID]: 'Lunas',
    [INVOICE_STATUS.PAYMENT_PENDING]: 'Sedang di-Invoice',
    [INVOICE_PAYMENT_STATUS.APPROVED]: 'Di-approve',
    [INVOICE_PAYMENT_STATUS.REJECTED]: 'Ditolak',
  },
  update: 'Perbaharui',
  update_stnk: 'Perbaharui STNK',
  invoice_sucsessfuly_sent: 'Invoice berhasil terkirim!',
  invoice_sucsessfuly_sent_to: 'Invoice {{invoiceNumber}} telah berhasil terkirim ke:',
  invoice_type: 'Jenis Invoice',
  invoice_type_filter: {
    [SHIPMENT_INVOICE_TYPE.ONCALL]: 'On Call',
    [SHIPMENT_INVOICE_TYPE.RENTAL]: 'Dedicated',
    [SHIPMENT_INVOICE_TYPE.PBI]: 'Panthera Biru Indonesia',
  },
  invoice_value: 'Nilai Invoice',
  invoices_details: 'Detail Faktur',
  invoicing_attention: 'Perhatian Faktur',
  is_return_fee_covered_map: {
    [IS_RETURN_FEE_COVERED.NEGOTIABLE]: 'Negotiable',
    [IS_RETURN_FEE_COVERED.NOT_COVERED]: 'Not Covered',
  },
  is_return_goods_map: {
    [IS_RETURN_GOODS.NO_RETURN]: 'Tidak Ada Pengembalian',
    [IS_RETURN_GOODS.RETURN_GOODS]: 'Kembalikan Barang',
  },
  invoice_schema_change_pbi: 'Perubahan Peraturan Skema Invoice PBI',
  item: 'Item',
  item_type: 'Tipe Barang',
  job_destination: 'Tujuan',
  job_origin: 'Asal',
  kargo_fee: 'Kargo Fee',
  kargo_fee_adjustment: 'Kargo Fee Adjustment',
  kargo_notes: 'Kargo Notes',
  kargo_system: 'Sistem Kargo',
  kg: 'Kg',
  kg_actual: 'Kg Aktual',
  kg_expected: 'Kg Ekspektasi',
  kir: 'KIR',
  kode_lambung: 'Kode Lambung',
  language: 'Bahasa',
  language_map: {
    en: 'English',
    id: 'Bahasa Indonesia',
  },
  last_disbursement_pph23_deductors: {
    [LAST_DISBURSEMENT_PPH23_DEDUCTOR.SHIPPER]: 'Shipper',
    [LAST_DISBURSEMENT_PPH23_DEDUCTOR.TRANSPORTER]: 'Transporter',
  },
  last_modified: 'Terakhir Diubah',
  last_updated_on: 'Terakhir diperbaharui pada',
  late: 'Terlambat',
  late_fee: 'Late Fee',
  latest_pickup: 'Muat Terakhir',
  latitude: 'Latitude',
  lead_time: 'Lead Time',
  leave_page: 'Tinggalkan Halaman',
  lembar_pajak: 'Lembar Pajak',
  license_plate: 'No. Polisi',
  license_plate_history: 'Sejarah No Polisi',
  license_plate_or_kode_lambung: 'No. Polisi atau Kode Lambung',
  line: 'Baris',
  load_and_unload: 'Muat & Bongkar',
  load_and_unload_location: 'Lokasi Bongkar & Muat',
  load_date_and_time: 'Tanggal & Waktu Muat',
  load_date: 'Tanggal Muat',
  load_time: 'Waktu Muat',
  load_information: 'Informasi Muatan',
  load_location: 'Lokasi Muat',
  load_more: 'Muat selanjutnya',
  load_schedule: 'Jadwal Muat',
  load: 'Muat',
  load_point: 'Muat {{pointName}}',
  loading_date: 'Tanggal Muat',
  loading_info: 'Muat Informasi',
  local_shipment_statuses: {
    [LOCAL_SHIPMENT_STATUSES.ALL]: 'Semua',
    [LOCAL_SHIPMENT_STATUSES.UPCOMING]: 'Butuh Konfirmasi',
    [LOCAL_SHIPMENT_STATUSES.PLANNED]: 'Dijadwalkan',
    [LOCAL_SHIPMENT_STATUSES.AT_WAREHOUSE]: 'Di Warehouse',
    [LOCAL_SHIPMENT_STATUSES.IN_TRANSIT]: 'Sedang Transit',
    [LOCAL_SHIPMENT_STATUSES.AT_LOCATION]: 'At Location',
    [LOCAL_SHIPMENT_STATUSES.ONGOING]: 'Berlangsung',
    [LOCAL_SHIPMENT_STATUSES.COMPLETED]: 'Riwayat',
  },
  location: 'Lokasi',
  location_change_reason: 'Alasan Perubahan Lokasi',
  location_change_reason_map: {
    [LOCATION_CHANGE_REASON.CHANGE_OF_PLANS]: 'Perubahan Rencana',
    [LOCATION_CHANGE_REASON.LOCATION_IS_FULL]: 'Lokasi Penuh',
    [LOCATION_CHANGE_REASON.OTHERS]: 'Lainnya',
  },
  location_fee: 'Tambah Biaya Lokasi',
  location_fee_type_map: {
    [LOCATION_FEE_TYPE.LOADING]: 'Muat',
    [LOCATION_FEE_TYPE.OTHER]: 'Lainnya',
    [LOCATION_FEE_TYPE.UNLOADING]: 'Bongkar',
  },
  location_name: 'Nama lokasi',
  location_type: {
    [LOCATION_TYPE.HOLDING]: 'Lokasi Penitipan',
    [LOCATION_TYPE.NORMAL]: 'Normal',
    [LOCATION_TYPE.PORT]: 'Pelabuhan',
    [LOCATION_TYPE.RETURN_DEPO]: 'Return Depo',
    [LOCATION_TYPE.STORE]: 'Store',
  },
  login: 'Masuk',
  logout: 'Keluar',
  longitude: 'Longitude',
  main_income: 'Pendapatan Utama',
  make_an_offer: 'Beri Tawaran',
  make_more_bidding_tooltip: 'Beri tawaran lebih banyak agar dapat mengambil muatan tanpa menawar',
  make_offer_no_vehicle_tooltip: 'Anda tidak bisa memberi tawaran karena Anda belum menambahkan jenis truk yang diminta oleh Customer',
  make_offer_waiting_verification_tooltip: 'Anda belum bisa memberi tawaran karena unit masih dalam proses verifikasi',
  manage_position_column: {
    [COACH_MARK_CONTENT.DESCRIPTION]: 'Arahkan kursor ke ikon ini, seret kolom ke posisi lain untuk memindahkan letak kolom',
    [COACH_MARK_CONTENT.TITLE]: 'Atur Posisi Kolom',
  },
  margin: 'Margin',
  mark_all_as_read: 'Semua sudah dibaca',
  mark_as_disbursed: 'Tandai Pencairan Ditransfer',
  mark_as_payment_received: 'Tandai pembayaran diterima',
  marketplace_budget: 'Marketplace Budget',
  mass_confirmation: 'Konfirmasi Massal',
  mass_confirmation_warning: 'Anda dapat mengambil semua pengiriman yang tersedia',
  mass_shipment_confirmation: 'Konfirmasi Pengiriman Massal',
  mass_shipment_confirmation_amount: 'Konfirmasi {{amount}} pengiriman?',
  maximum: 'Maksimum',
  max_bid_limit: 'Max Bid Limit',
  max_columns_message: 'Anda tidak boleh memiliki lebih dari 10 kolom',
  max_file_uploaded_is: 'Maksimal file yang dapat diunggah adalah {{amount}} file. Mohon cek kembali',
  max_reimbursable_amount: 'Jumlah maksimum yang dapat di-reimburse',
  max_transporter_bid_limit: 'Max Transporter Bid Limit',
  maximum_amount: 'Jumlah maksimal',
  measurement_unit: 'Satuan',
  message: 'Pesan',
  mileage: 'Jarak Tempuh',
  minimum: 'Minimum',
  min_quantity_to_be_paid: 'Min Quantity to be Paid',
  min_shipper_price: 'Minimum Shipper Price',
  minimum_hours: 'Waktu Minimal',
  minimum_quantity: 'Jumlah Minimum',
  missing_goods: 'Barang Hilang',
  mobile_no: 'No Handphone',
  month: 'Bulan',
  month_setup: 'Pengaturan Bulan',
  npwp: 'NPWP',
  name: 'Nama',
  need_confirmation: 'Butuh Konfirmasi',
  negotiation_price: 'Harga Negosiasi',
  new: 'Baru',
  new_backdated_shipment: 'Pengiriman Lama',
  new_customer: 'Customer Baru',
  new_driver: 'Supir Baru',
  new_location: 'Lokasi Baru',
  new_password: 'Password Baru',
  new_shipment: 'Pengiriman Baru',
  new_unit: 'Unit Baru',
  new_warehouse: 'Gudang Baru',
  next: 'Lanjut',
  next_location: 'Lokasi Berikutnya',
  next_step: 'Langkah Selanjutnya',
  nexus_container_number: 'Nomor Kontainer',
  nft: {
    active: 'Aktif',
    non_active: 'Tidak Aktif',
    grace_period: 'Masa Tenggang',
    suspended: 'Ditangguhkan',
  },
  no: 'Tidak',
  no_cancel_it: 'Tidak, Batalkan',
  no_data_during_this_time_frame: 'Tida ada data',
  no_of_submission: 'No. Pengajuan',
  no_po: 'No. Po',
  nominal: 'Nominal',
  non_actual_route: 'Rute Tidak Aktual',
  not_added_truck: 'Belum Ditambahkan',
  not_registered: 'Belum terdaftar',
  not_uploaded: 'Belum Diupload',
  notes: 'Catatan',
  notes_for_driver: 'Catatan untuk Supir',
  notes_transporter: 'Notes Transporter',
  notification: 'Notifikasi',
  now_location: 'Lokasi Terkini',
  npwp_number: 'Nomor NPWP',
  number_of_customer_transfer: 'Jumlah Transfer Customer',
  number_of_shipment: 'Jumlah Pengiriman',
  office_address: 'Alamat Kantor',
  ok: 'Ok',
  ok_caps: 'OK',
  on_call_invoice: 'On Call Invoice',
  onhold_invoice_reason: {
    [ONHOLD_INVOICE_REASON.WAITING_FOR_OVERNIGHT]: 'Menunggu Biaya Inap.',
    [ONHOLD_INVOICE_REASON.WAITING_FOR_CLAIM]: 'Menunggu Klaim.',
    [ONHOLD_INVOICE_REASON.WAITING_FOR_DOCUMENT]: 'Menunggu Dokumen.',
    [ONHOLD_INVOICE_REASON.CHECKING_PROCESS]: 'Proses Pengecekan.',
    [ONHOLD_INVOICE_REASON.OTHER]: 'Lainnya',
  },
  onboarding_date: 'Tanggal Orientasi',
  ongoing_shipment: 'Pengiriman Berlangsung',
  operational_hours: 'Jam Operasional',
  optional: 'Opsional',
  order: 'Pesanan',
  order_details: 'Detail Pesanan',
  order_number: 'Nomor Order',
  order_type: 'Jenis Pesanan',
  origin_warehouse_name: 'Asal',
  origin: 'Asal',
  other: 'Lainnya',
  other_document: 'Dokumen Lainnya',
  other_fee: 'Biaya Lain',
  other_fees: 'Biaya Lainnya',
  other_income: 'Pendapatan Lain',
  other_notes: 'Other Notes',
  overnight_fees_labels: {
    include_holiday: 'Termasuk Hari Libur',
    include_sunday: 'Termasuk Hari Minggu',
    minimum_hours: 'Jam Minimal',
    overnight_fee: 'Biaya Bermalam',
    overnight_fees: 'Biaya Bermalam',
    remarks: 'Catatan',
    required_documents: 'Dokumen Wajib',
  },
  overnight_loading_info: 'Informasi Muat Lembur',
  overnight_unloading_info: 'Informasi Bongkar Lembur',
  overnight_proof_description: {
    DRIVER_APP_GPS_TIMESTAMPS: '',
    FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'Tidak dicantumkan dalam POD',
    OTHER: '',
    SHIPPER_APPROVAL: '',
    WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'Ditulis dan dicap basah di POD',
  },
  overnight_proof_type: {
    DRIVER_APP_GPS_TIMESTAMPS: 'Waktu di GPS Aplikasi Pengemudi',
    FORM_OR_BERITA_ACARA_OR_PROOF_OF_OVERNIGHT_STAY: 'Formulir, Berita Acara, atau Bukti Menginap',
    OTHER: 'Lainnya',
    SHIPPER_APPROVAL: 'Persetujuan dari Shipper',
    WAREHOUSE_ENTRY_AND_EXIT_TIMESTAMPS: 'Tanggal Masuk dan Keluar Gudang',
  },
  owner_email: 'Email Pemilik',
  owner_name: 'Nama Pemilik',
  packaging_damage: 'Kemasan Rusak',
  pagination: {
    first: 'Awal',
    many: 'Banyak',
    next: 'Selanjutnya',
    page: 'halaman',
    prev: 'Sebelumnya',
  },
  panthera_do_number: 'DO Number panthera',
  panthera_poc: 'Panthera POC',
  panthera_system_disbursement: 'Pencairan dari sistem Panthera Biru Indonesia',
  pod_pol_number: 'Nomor Surat Jalan',
  password: 'Password',
  past_seven_days: '7 Hari Terakhir',
  past_three_months: '3 Bulan Terakhir',
  payload_details: 'Detil Muatan',
  payment_and_tax_details: 'Detail Pembayaran & Pajak',
  payment_date: 'Tgl. Pembayaran',
  payment_details: 'Rincian Pembayaran',
  payment_info: 'Info Pembayaran',
  payment_information: 'Informasi Pembayaran',
  payment_item: 'Item Pembayaran',
  payment_method: 'Cara Pembayaran',
  payment: 'Pembayaran',
  payments: 'Payments',
  payment_request_status_map: {
    APPROVED: 'Proses Pencairan',
    PENDING: 'Proses Konfirmasi',
    DISBURSEMENT_FAILED: 'Proses Pencairan',
    ON_HOLD: 'Ditunda',
    PROCESSING: 'Proses Pencairan',
    REVIEWED: 'Proses Review',
    FEE_REJECTED: 'Ditolak',
    REQUEST_SENT: 'Proses Review',
    CANCELLED: 'Cancelled',
    REQUEST_APPROVED: 'Proses Pencairan',
    DISBURSED: 'Lunas',
    DELETED: 'Dihapus',
  },
  payment_status: 'Status Pembayaran',
  // Do not sort (large objects is set at the bottom to make it easier to read)
  payment_shipment_tab: {
    amount_claimed: 'Jumlah Klaim',
    customer: 'Pelanggan',
    inserted_at: 'Tanggal Dibuat',
    license_plate: 'Nomor Polisi',
    loading_date: 'Tanggal Muat',
    shipment_number: 'Nomor Pengiriman',
    status: 'Status',
    status_last_update: 'Tanggal Update Status',
    total_fees: 'Total Tagihan',
    additional_notes: 'Catatan Tambahan',
    claim_fee: 'Biaya Klaim',
    claim_notes: 'Klaim Notes',
    claim_type: 'Tipe Klaim',
    claim_types_map: {
      'Missing Goods': 'Barang Hilang',
      Damage: 'Barang Rusak',
      'Packaging Damage': 'Packaging Rusak',
      'Goods Near Expiry Date': 'Barang ditolak karena hampir expired',
      'Shipper\'s Fault - Wrong Goods': 'Salah Barang',
      'Shipper\'s Fault - Goods Too Many': 'Barang Selisih - Lebih Barang',
      'Goods Too Few': 'Barang Selisih - Kurang Barang',
    },
    customizable_invoice_status_map: {
      [PAYMENT_SUMMARIES_SECTION_STATUSES.DISBURSED]: 'Lunas',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.NEED_INVOICED]: 'Perlu Ditagih',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.ON_HOLD]: 'Ditunda',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.PROCESS]: 'Diproses',
      [PAYMENT_SUMMARIES_SECTION_STATUSES.NOT_DISBURSED]: 'Belum Lunas',
    },
    day_count: 'Perhitungan Hari',
    fee_types_info_map: {
      [BTMS_FEE_TYPES.SHIPMENT]: 'Biaya Pengiriman',
      [BTMS_FEE_TYPES.LOADING]: 'Biaya Muat',
      [BTMS_FEE_TYPES.UNLOADING]: 'Biaya Bongkar',
      [BTMS_FEE_TYPES.OTHER]: 'Biaya Lain di Lokasi',
      [BTMS_FEE_TYPES.OVERNIGHT_LOADING]: 'Biaya Inap Muat',
      [BTMS_FEE_TYPES.OVERNIGHT_UNLOADING]: 'Biaya Inap Bongkar',
      [BTMS_FEE_TYPES.RETURNS]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.ADDITIONAL]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.TICKET]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.CLAIMS]: 'Claim',
      [BTMS_FEE_TYPES.INSURANCE]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.PENALTY]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.ADJUSTMENT]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.OTHER_ADJUSTMENT]: 'ADJUSTMENT_FEE',
    },
    fee_types_title_map: {
      [BTMS_FEE_TYPES.SHIPMENT]: 'Surat Jalan',
      [BTMS_FEE_TYPES.LOADING]: 'Biaya Muat',
      [BTMS_FEE_TYPES.UNLOADING]: 'Biaya Bongkar',
      [BTMS_FEE_TYPES.OTHER]: 'Biaya Parkir',
      [BTMS_FEE_TYPES.OVERNIGHT_LOADING]: 'Biaya Inap Muat',
      [BTMS_FEE_TYPES.OVERNIGHT_UNLOADING]: 'Biaya Inap Bongkar',
      [BTMS_FEE_TYPES.RETURNS]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.ADDITIONAL]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.TICKET]: 'ADDITIONAL_FEE',
      [BTMS_FEE_TYPES.CLAIMS]: 'Claim',
      [BTMS_FEE_TYPES.INSURANCE]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.PENALTY]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.ADJUSTMENT]: 'ADJUSTMENT_FEE',
      [BTMS_FEE_TYPES.OTHER_ADJUSTMENT]: 'ADJUSTMENT_FEE',
    },
    max_reimbursable_amount: 'Jumlah Reimburse Maks.',
    minimum_hours: 'Jam Minimum',
    parking_fee: 'Biaya Parkir',
    payment_summaries_status_map: {
      [PAYMENT_SUMMARIES_STATUSES.CANCELLED]: 'Dibatalkan',
      [PAYMENT_SUMMARIES_STATUSES.DISBURSED]: 'Lunas',
      [PAYMENT_SUMMARIES_STATUSES.FULLY_INVOICED]: 'Ditagih',
      [PAYMENT_SUMMARIES_STATUSES.NOT_INVOICED]: 'Belum Ditagih',
      [PAYMENT_SUMMARIES_STATUSES.ONGOING_SHIPMENT]: 'Pengiriman Masih Diproses',
      [PAYMENT_SUMMARIES_STATUSES.ON_HOLD]: 'Ditunda',
      [PAYMENT_SUMMARIES_STATUSES.PARTIALLY_DISBURSED]: 'Lunas Sebagian',
      [PAYMENT_SUMMARIES_STATUSES.PARTIALLY_INVOICED]: 'Ditagih Sebagian',
      [PAYMENT_SUMMARIES_STATUSES.REJECTED]: 'Ditolak',
      [PAYMENT_SUMMARIES_STATUSES.REQUEST_APPROVED]: 'Proses Pencairan',
      [PAYMENT_SUMMARIES_STATUSES.REVIEW_SHIPMENT_FEE]: 'Proses Konfirmasi',
      [PAYMENT_SUMMARIES_STATUSES.REVIEW_IN_PROCESS]: 'Proses Review',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_CLAIM]: 'Menunggu Claim',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_INVOICE_REVISION]: 'Menunggu Revisi Invoice',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_OVERNIGHT]: 'Menunggu Biaya Inap',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_PENALTY]: 'Menunggu Pinalti',
      [PAYMENT_SUMMARIES_STATUSES.WAITING_FOR_TKBM]: 'Menunggu TKBM',
    },
    product_name: 'Nama Produk',
    received_amount_at_pitstop: 'Jumlah Diterima Di Lokasi',
    received_amount_returned: 'Jumlah Dikembalikan',
    reimbursement_requirement: 'Persyaratan Reimbursement',
    reimbursement_type: 'Tipe Reimbursement',
    reimbursement_terms: 'Syarat Reimburse',
    truck_type: 'Tipe Truk',
    total_payment: 'Jumlah Pembayaran',
    external_id: 'Eksternal ID',
  },
  payment_type: {
    [PAYMENT_TYPES.OTHER_FEE]: 'Biaya Lain',
    [PAYMENT_TYPES.LOADING_FEE]: 'Biaya Muat',
    [PAYMENT_TYPES.OVERNIGHT_FEE]: 'Biaya Inap',
    [PAYMENT_TYPES.SHIPMENT_FEE]: 'Biaya Trucking',
    [PAYMENT_TYPES.TICKET_FEE]: 'Biaya Tilang',
    [PAYMENT_TYPES.UNLOADING_FEE]: 'Biaya Bongkar',
  },
  percentage_of_shipper_price: '% dari Harga Pengirim',
  penerimaan_do: 'Penerimaan DO',
  performance: 'Performa',
  performance_report: 'Performa Lokasi',
  performance_report_transporter: 'Laporan Performa Transporter',
  ketepatan_waktu: 'Ketepatan Waktu',
  personal_account: 'Akun Pribadi',
  phone: 'Telepon',
  phone_number: 'Nomor Handphone',
  physical_invoice: 'Invoice Fisik',
  pic: 'PIC',
  pic_name: 'Nama PIC',
  pickup: 'Muat',
  pickup_and_dropoff: 'Bongkar dan Muat',
  pkp_number: 'Nomor PKP',
  planned_loading: 'Rencana Muat',
  planned_unloading: 'Rencana Bongkar',
  plate_color: 'Warna Plat',
  plate_colors: 'Warna Plat',
  plate_color_array: {
    [PLATE_COLORS.BLACK_PLATE]: 'Hitam',
    [PLATE_COLORS.YELLOW_PLATE]: 'Kuning',
  },
  plate_number_histories_column: {
    [PLATE_NUMBER_HISTORIES_COLUMN.LICENSE_PLATE]: 'No. Polisi',
    [PLATE_NUMBER_HISTORIES_COLUMN.STATUS]: 'Status',
    [PLATE_NUMBER_HISTORIES_COLUMN.TIMESTAMP]: 'Tanggal',
    [PLATE_NUMBER_HISTORIES_COLUMN.UPDATED_BY]: 'Diubah Oleh',
  },
  po_number: 'No. PO',
  pod_details: 'Detail POD',
  pod_number: 'Nomor POD',
  pod_uploaded_on: 'Surat jalan diunggah pada',
  podf: 'PODF',
  podf_actual_top: 'PF1 Actual ToP',
  podf_first_disbursement_formula: '80% Invoice + PPN - PPH - Kargo Fee - VAT',
  podf_invoice_receipt_rejection_reason_map: {
    [PODF_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Tanda Terima Invoice Buram',
    [PODF_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'Nomor Invoice tidak sesuai dengan Tanda Terima',
    [PODF_INVOICE_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  podf_invoice_rejection_reason_map: {
    [PODF_INVOICE_REJECTION_REASON.INVOICE_BLUR]: 'Invoice Buram',
    [PODF_INVOICE_REJECTION_REASON.INVOICE_NUMBER_MISMATCH]: 'No. Invoice tidak ada di tanda terima Invoice',
    [PODF_INVOICE_REJECTION_REASON.OTHER]: 'Lainnya',
  },
  podf_request_number: 'PF1 Request Number',
  podf_request_on_hold_reason: {
    [PODF_REQUEST_ON_HOLD_REASON.ALL_DOCUMENTS_REJECTED]: 'Revisi pengajuan berdasarkan alasan yang diberikan untuk memproses pencairan',
    [PODF_REQUEST_ON_HOLD_REASON.CREDIT_LIMIT_EXCEEDED]: 'Pengajuan Melebihi batas peminjaman uang',
    [PODF_REQUEST_ON_HOLD_REASON.LATE_PAYMENT_ISSUE]: 'Masalah Pembayaran terlambat',
    [PODF_REQUEST_ON_HOLD_REASON.OTHER]: 'Lainnya',
  },
  point_type: 'Tipe Poin',
  pool: 'Pool',
  postcode: 'Kodepos',
  pph: 'PPH',
  pph23: 'PPh 23',
  pph23_deductor: 'Pemotong PPh23',
  pph23_deductors: {
    [PPH23_DEDUCTOR.PARTNER]: 'Customer',
    [PPH23_DEDUCTOR.SELF]: 'PT saya',
  },
  pph23_detail: 'Detail PPh23',
  pph23_discount: 'Potongan PPh 23 kepada',
  ppn: 'PPN',
  ppn_detail: 'Tambah Detail PPN',
  preview: 'Preview',
  preview_invoice: 'Preview Invoice',
  previous: 'Sebelumnya',
  previous_location: 'Lokasi Sebelumnya',
  previous_month: 'Bulan Sebelumnya',
  pr_number: 'Nomor PR',
  price: 'Harga',
  price_calculation: 'Kalkulasi Harga',
  price_rate_type: 'Price Rate Type',
  price_adjustment: 'Penyesuaian Harga',
  prices: 'Harga',
  pricing: 'Harga',
  pricing_type: 'Tipe Harga',
  print: 'Cetak',
  print_english: 'Print',
  product_category: 'Kategori Produk',
  product_category_map: {
    [PRODUCT_CATEGORY.ALCOHOLIC_BEVARAGES]: 'Minuman Beralkohol',
    [PRODUCT_CATEGORY.BAKERY]: 'Toko Roti',
    [PRODUCT_CATEGORY.COFFEE]: 'Kopi',
    [PRODUCT_CATEGORY.CONDIMENTS_SOUCES]: 'Bumbu & Saus',
    [PRODUCT_CATEGORY.COOKING_BAKING_INGREDIENTS]: 'Bahan Memasak & Memanggang',
    [PRODUCT_CATEGORY.DAIRY]: 'Produk Susu (Dingin)',
    [PRODUCT_CATEGORY.DIPS]: 'Dips & Spread',
    [PRODUCT_CATEGORY.DRINKING_WATER]: 'Air Minum',
    [PRODUCT_CATEGORY.FITNESS_AND_NUTRITION]: 'Kebugaran & Nutrisi',
    [PRODUCT_CATEGORY.FMCG]: 'FMCG',
    [PRODUCT_CATEGORY.FOOD]: 'Keranjang Hadiah Makanan',
    [PRODUCT_CATEGORY.FROZEN]: 'Makanan Penutup & Hal Baru Beku',
    [PRODUCT_CATEGORY.FRUIT_FLAVORED_DRINKS]: 'Minuman Rasa Buah',
    [PRODUCT_CATEGORY.FRUITS]: 'Buah & Sayuran',
    [PRODUCT_CATEGORY.GRAINS]: 'Biji-bijian, Beras & Sereal',
    [PRODUCT_CATEGORY.JUICE]: 'Jus',
    [PRODUCT_CATEGORY.MEAT]: 'Daging, Makanan Laut & Telur',
    [PRODUCT_CATEGORY.MEDICINE_AND_DRUGS]: 'Obat & Obat',
    [PRODUCT_CATEGORY.MILK]: 'Susu',
    [PRODUCT_CATEGORY.NON_DAIRY]: 'Susu Non-Susu',
    [PRODUCT_CATEGORY.NUTS]: 'Kacang & Biji',
    [PRODUCT_CATEGORY.PACKAGING]: 'Kemasan',
    [PRODUCT_CATEGORY.PASTA]: 'Pasta & Mie',
    [PRODUCT_CATEGORY.PLASTIC]: 'Pellet Plastik',
    [PRODUCT_CATEGORY.POWDERED_BEVERAGES_MIXES]: 'Campuran Minuman Serbuk',
    [PRODUCT_CATEGORY.PREPARED]: 'Makanan Jadi',
    [PRODUCT_CATEGORY.SNACKS]: 'Makanan Ringan',
    [PRODUCT_CATEGORY.SNAKES]: 'Makanan Ringan',
    [PRODUCT_CATEGORY.SODA]: 'Soda',
    [PRODUCT_CATEGORY.SOUPS]: 'Sup & Kaldu',
    [PRODUCT_CATEGORY.SPICES]: 'Bumbu & Rempah',
    [PRODUCT_CATEGORY.SPORTS_ENERGY_DRINKS]: 'Olahraga & Minuman Energi',
    [PRODUCT_CATEGORY.TEA_INFUSIONS]: 'Teh & Infus',
    [PRODUCT_CATEGORY.TOUFU]: 'Produk Tahu, Kedelai & Vegetarian',
    [PRODUCT_CATEGORY.WATER]: 'Air',
    // Note: From BTMS Products
    [PRODUCT_CATEGORY.AUTOMOTIVE]: 'Automotive',
    [PRODUCT_CATEGORY.MOVING_GOODS]: 'Moving Goods',
    [PRODUCT_CATEGORY.CONSTRUCTION_MATERIAL]: 'Construction Material',
    [PRODUCT_CATEGORY.HEAVY_EQUIPMENT]: 'Heavy Equipment',
    [PRODUCT_CATEGORY.FROZEN_GOODS]: 'Frozen Goods',
    [PRODUCT_CATEGORY.AGRICULTURAL_PRODUCT]: 'Agricultural Product',
    [PRODUCT_CATEGORY.GENERAL_CARGO]: 'General Cargo',
    [PRODUCT_CATEGORY.GLASSWARE]: 'Glassware',
    [PRODUCT_CATEGORY.PRODUCT_PACKAGING]: 'Product Packaging',
  },
  product_details: 'Detail Muatan',
  product_type: 'Tipe Produk',
  proforma_invoice: 'Proforma Invoice',
  progressive_pricing_type: 'Progresif',
  proof_of_cancellation: 'Lampiran bukti pembatalan',
  proof_of_cancellation_type: {
    DRIVER_APP_GPS: 'GPS aplikasi Driver',
    EMAIL_APPROVAL: 'Persetujuan lewat Email',
    WHATSAPP_APPROVAL: 'Persetujuan lewat WhatsApp',
  },
  province: 'Provinsi',
  puspakom: 'Inspeksi Puspakom',
  pt: 'PT',
  quantities: 'Kuantitas',
  question_for_kargo: 'pertanyaan untuk Kargo?',
  question_for_kargo_payment: 'pertanyaan untuk pembayaran Kargo?',
  queueing_time: 'Queueing Time',
  readiness_shipment_status_map: {
    [FLEET_MONITORING_STATUS.AVAILABLE]: 'Tersedia',
    [FLEET_MONITORING_STATUS.ONGOING]: 'Sedang Berjalan',
    [READINESS_SHIPMENT_STATUS.ABOUT_TO_BE_READY]: 'Segera Ready',
    [READINESS_SHIPMENT_STATUS.READY]: 'Ready',
  },
  real_add_additional_fee: 'Masukan Biaya Tambahan',
  receipt_details: 'Detail Tanda Terima',
  receipt_number: 'Nomor Resi',
  receipt_number_placeholder: 'Masukkan nomor resi pengiriman',
  receive_time: 'Waktu Terima',
  receiver_name: 'Nama Penerima',
  recipients_placeholder: 'Ketik email tujuan. Contoh: “budi@email.com”',
  reconfirmation: 'Konfirmasi Ulang',
  refuse_shipment: 'Tolak Pengiriman',
  refusing_job: 'Menolak pengiriman ini akan mengurangi penilaian performa Anda yang sudah diatur dalam kontrak dengan customer.',
  regarding: 'Perihal',
  register: 'Daftar',
  registered_company_name: 'Nama Perusahaan Terdaftar',
  registered_to_swift_payment_program: 'Terdaftar Program Pembayaran Cepat',
  reimbursement_percentage: 'Persentase reimbursement',
  reimbursement_requirement: 'Syarat reimbursement',
  reimbursement_requirement_description: 'Ketik syarat reimbursement di sini',
  reimbursement_requirement_type_map: {
    [REIMBURSEMENT_REQUIREMENT_TYPE.NOT_REIMBURSABLE]: 'Tidak bisa direimburse',
    [REIMBURSEMENT_REQUIREMENT_TYPE.OTHER]: 'Lainnya',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE]: 'Bisa direimburse',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT]: 'Reimburse dengan tanda terima',
    [REIMBURSEMENT_REQUIREMENT_TYPE.REIMBURSABLE_WITH_RECEIPT_AND_STAMP]: 'Reimburse dengan tanda terima dan cap',
  },
  reimbursement_type: 'Tipe reimbursement',
  reimbursement_type_description: 'Ketik tipe reimbursement di sini',
  rejected: 'Ditolak',
  rejected_by_shipper: 'Dibatalkan Customer',
  rejected_by_vendor: 'Dibatalkan Vendor',
  rejected_dof_shipment_list_details: 'Rincian Pengiriman yang Ditolak',
  rejected_podf_invoice_details: 'Rincian Invoice Ditolak',
  rejection_category: 'Kategori Penolakan',
  rejection_reason: 'Alasan Penolakan',
  remaining_lead_time: 'Sisa Lead Time',
  remarks: 'Keterangan',
  rent_period: 'Periode Sewa',
  rental_price: 'Harga Sewa',
  rental_column_deleted: 'Kolom telah terhapus',
  repayment: 'Pelunasan',
  repayment_date: 'Tgl. Pelunasan',
  report: 'Laporan',
  report_tab: {
    shipmentAcceptance: {
      datetimeRanges: 'Rentang Tanggal',
      process: 'Proses',
      doTotal: 'Total DO',
      acceptedDoPercentage: 'Persentase penerimaan DO',
      status: {
        [SHIPMENT_ACCEPTANCE_STATUS.ACCEPTED]: 'DO diterima',
        [SHIPMENT_ACCEPTANCE_STATUS.CANCELLED_BY_SHIPPER]: 'DO dibatalkan shipper',
        [SHIPMENT_ACCEPTANCE_STATUS.REJECTED_BY_TRANSPORTER]: 'DO ditolak vendor',
        [SHIPMENT_ACCEPTANCE_STATUS.CONFIRMATION_EXPIRED]: 'DO tidak dikonfirmasi',
      },
    },
    shipmentTimeAccuracy: {
      warehouseLocation: 'Lokasi',
      total_do: 'Total DO',
      status: {
        [SHIPMENT_TIME_ACCURACY_STATUS.AHEAD]: 'Lebih Cepat',
        [SHIPMENT_TIME_ACCURACY_STATUS.ONTIME]: 'Tepat Waktu',
        [SHIPMENT_TIME_ACCURACY_STATUS.LATE]: 'Terlambat',
      },
    },
    vehicle_mileage: {
      average_mileage: 'Rata-rata Jarak Tempuh (Km)',
      title: 'Cari Riwayat Jarak Tempuh',
      report_type: 'Tipe Laporan',
      tooltip_info: 'Bagaimana cara menghitung rata-rata jarak tempuh?',
      calculate_average_mileage: 'Perhitungan Rata-rata Jarak Tempuh',
      calculate_total_mileage: 'Perhitungan Total Jarak Tempuh',
      total_mileage: 'Total Jarak Tempuh (Km)',
      route: 'Rute',
    },
    datetimeRanges: 'Rentang Tanggal',
    initialScreenTitle: 'Silahkan Input Data Yang Mau Anda Cari',
    total_do: 'Total Jumlah DO',
    total_mileage: 'Total Jarak Tempuh',
    trip_details: 'Detail Perjalanan',
    truckType: 'Tipe Armada',
    utilization: {
      actual_utilization_label: 'Total Lead Time Aktual',
      allocated_utilization_label: 'Total Target Lead Time',
      avg_completed_shipment_label: 'Rata-rata Jumlah DO',
      avg_actual_lead_time: 'Rata-rata Lead Time Aktual',
      avg_actual_utilization_label: 'Rata-rata Utilisasi Aktual',
      avg_allocated_utilization_label: 'Estimasi Rata-rata Utilisasi',
      avg_target_lead_time: 'Rata-rata Target Lead Time',
      company_working_days: 'Hari Kerja Perusahaan',
      current_utilization: 'Utilisasi Saat Ini',
      current_utilization_calculation: 'Perhitungan Utilisasi Saat Ini',
      do_number: 'No. DO',
      each_month: '/ Bulan',
      performance_analysis: 'Analisis Performa',
      target_lead_time: 'Target Lead Time',
      target_lead_time_actual: 'Lead Time Aktual',
      total_do: 'Jumlah DO',
      total_target_lead_time: 'Target Lead Time',
      total_target_lead_time_actual: 'Lead Time Aktual',
      tooltip_content: 'Perhitungan jumlah waktu utilisasi aktual dimulai ketika driver berangkat ke lokasi muat yang pertama hingga selesai bongkar di lokasi terakhir. Apabila terdapat waktu tunggu di antara setiap lokasi, maka waktu tunggu tersebut ikut dihitung.',
      tooltip_label: 'Bagaimana cara menghitung utilisasi saat ini?',
      tooltip_title: 'Perhitungan Utilisasi Aktual',
      utilization_target_label: 'Target Utilisasi Perusahaan',
      utilization_detail: 'Detail Utilisasi',
    },
  },
  request_type: 'Tipe Request',
  vehicle_report_type_map: {
    [VEHICLE_REPORT_TYPE.TOTAL_KM]: 'Jarak Tempuh Total',
    [VEHICLE_REPORT_TYPE.PER_ROUTE_KM]: 'Jarak Tempuh Per Rute',
  },
  reset: 'Reset',
  resend_verification_code: 'Kirim ulang kode',
  respond: 'Tanggapi',
  return: 'Pengembalian',
  return_deadline: 'Batas Waktu Pengembalian',
  return_fee: 'Biaya Pengembalian',
  return_schedule: 'Jadwal Kembali',
  retype_new_password: 'Ulangi Password Baru',
  retype_password: 'Ulangi Password',
  reupload_file: 'Reupload File',
  reupload_npwp: 'Unggah Ulang NPWP',
  reupload: 'Unggah Ulang',
  review: 'Ulasan',
  review_document: 'Review Dokumen',
  review_pod: 'Review SJ Bongkar',
  review_invoice: 'Review Invoice',
  revised_invoice: 'Invoice Revisi',
  revisi_invoice: 'Revisi Invoice',
  road_fee: 'Uang Jalan',
  role: 'Wewenang',
  route: 'Rute',
  routes: 'Rute',
  route_type: 'Tipe Rute',
  route_type_map: {
    [ROUTE_TYPES.SINGLE]: 'Single Pickup and Dropoff',
    [ROUTE_TYPES.MULTI]: 'Multiple Pickup/Dropoff',
  },
  safety_req: 'Ketentuan Keamanan',
  sales: 'Penjualan',
  sales_support: 'Dukungan Penjualan',
  save: 'Simpan',
  save_and_continue: 'Simpan & Lanjutkan',
  save_and_create: 'Simpan Dan Buat',
  save_and_exit: 'Simpan & Keluar',
  save_and_send: 'Simpan & Kirim',
  save_changes: 'Simpan Perubahan',
  save_customer: 'Simpan Customer',
  save_driver: 'Simpan Supir',
  save_format: 'Simpan Format',
  save_location: 'Simpan Lokasi',
  save_password: 'Simpan Password',
  save_shipment: 'Simpan Pengiriman',
  save_status: 'Simpan Status',
  save_template: 'Simpan Template',
  save_warehouse: 'Simpan Gudang',
  saved_warehouse: 'Gudang Tersimpan',
  search: 'Cari',
  select_delivery_order_type: 'Pilih Jenis Pengiriman untuk',
  send_physical_invoice_title: 'Kirim Invoice Fisik',
  scheme: 'Skema',
  search_address: 'Alamat Pencarian',
  search_do_number_and_license_number: 'Cari Nomor DO, Nomor Lisensi',
  search_file: 'Cari File',
  search_shipment_number: 'No.Pengiriman',
  search_shipment_no_license_plate_serial_no_body_code: 'No.Polisi, No.Pengiriman, Serial No., Kode Lambung',
  search_shipment_no_license_plate_serial_no: 'No.Polisi, No.Pengiriman, Serial No.',
  search_shipment_non_serial_no: 'No.Pengiriman, Serial No.',
  search_external_id: 'Cari Eksternal ID',
  search_license_number: 'Cari No. Polisi',
  search_vehicle_availablity: 'Cari Ketersediaan Armada',
  second: 'Kedua',
  second_disbursement: 'Pencairan Kedua',
  see_details: 'Lihat Detail',
  see_document_claim: 'Lihat Dokumen Klaim',
  see_invoice_receipt: 'Lihat Tanda Terima Invoice',
  see_less: 'Lihat Lebih Sedikit',
  see_list_of_units: 'Lihat Daftar Unit',
  see_more: 'Lihat selengkapnya',
  see_revised_invoice: 'Lihat Revisi Invoice',
  see_the_rest: 'Lihat Selebihnya',
  select: 'Pilih',
  select_all: 'Select All',
  select_as: 'pilih sebagai',
  select_column: 'Pilih Kolom',
  select_customer_and_shipments: 'Pilih pelanggan dan pengiriman',
  select_customer_placeholder: 'Pilih pelanggan',
  select_customer: '"Pilih pelanggan sebelum melanjutkan..."',
  select_file: 'Select File',
  select_reason_for_cancellation: 'Pilih alasan',
  select_reason_for_rejection: 'Pilih alasan penolakan',
  select_unit: 'Pilih Unit',
  send_bid: 'Kirim Tawaran',
  send_email: 'Kirim Email',
  send_invoice: 'Kirim Invoice',
  send_invoice_reminder: 'Kirim pengingat faktur ke kontak ini',
  send_invoice_reminder_every: 'Kirim pengingat faktur setiap',
  send_invoice_reminder_via: 'Kirim pengingat faktur melalui',
  send_revise: 'Kirim Revisi',
  send_updated: 'Kirim Pembaruan',
  send_to: 'Kirim',
  serial_number: 'Serial No.',
  shipment: 'Pengiriman',
  shipment_cost: 'Biaya pengiriman',
  shipment_denied: 'Pengiriman Ditolak',
  shipment_details: 'Detail Pengiriman',
  shipment_document_type_map: {
    'claims-returns': 'Klaim dan Pengembalian',
    'internal-pod': 'SJ Bongkar',
    'internal-pol': 'SJ Muat',
    'other-document': 'Dokumen Lain',
  },
  shipment_drop: 'Multi-Drop',
  shipment_expense_types: {
    [SHIPMENT_EXPENSE_TYPES.DRIVER_SALARY_EXPENSE]: 'Gaji Driver',
    [SHIPMENT_EXPENSE_TYPES.LOADING_EXPENSE]: 'Biaya Muat',
    [SHIPMENT_EXPENSE_TYPES.OTHER_EXPENSE]: 'Biaya Lain-lain',
    [SHIPMENT_EXPENSE_TYPES.OVERNIGHT_EXPENSE]: 'Biaya Inap Supir',
    [SHIPMENT_EXPENSE_TYPES.TRAVEL_EXPENSE]: 'Uang Jalan',
    [SHIPMENT_EXPENSE_TYPES.UNLOADING_EXPENSE]: 'Biaya Bongkar',
  },
  shipment_expenses_table: {
    edit: 'Ubah',
  },
  shipment_fee: 'Harga Pengiriman',
  shipment_fees_table: {
    amount: 'Jumlah',
    attachment: 'Lampiran',
    description: 'Deskripsi',
    doInvoice: 'Invoice Terkait',
    fee_type: 'Tipe Biaya',
    notes: 'Catatan',
    related_pr: 'PR Terkait',
    status: 'Status',
    view_detail: 'Lihat Detail',
  },
  shipment_history: 'Riwayat Perjalanan',
  shipment_job_status_map: {
    [JOB_STATUSES.UNASSIGNED]: 'Unassigned',
  },
  shipment_no: 'No. Pengiriman',
  shipment_no_table: 'No. Pengiriman',
  shipment_number: 'Nomor Pengiriman',
  shipment_numbers_selecter: '{{count}} nomor pengiriman dipilih',
  shipment_pickup: 'Jadwal Muat',
  shipment_point_label_map: {
    [SHIPMENT_POINT_TYPES.DROPOFF]: 'Lokasi Bongkar',
    [SHIPMENT_POINT_TYPES.PICKUP]: 'Lokasi Muat',
    [SHIPMENT_POINT_TYPES.PICKUP_DROPOFF]: 'Lokasi Bongkar & Muat',
  },
  shipment_price: 'Biaya Trucking',
  shipment_quantities_delivered: 'Jumlah Barang Terkirim',
  shipment_route: 'Rute Pengiriman',
  shipment_mile_type_map: {
    MID_MILE: 'Mid Mile',
    FIRST_MILE: 'First Mile',
  },
  shipment_status_map: {
    [SHIPMENT_STATUSES.ARRIVE_AT_DESTINATION]: 'Tiba di Lokasi Bongkar',
    [SHIPMENT_STATUSES.ARRIVE_AT_PICKUP]: 'Tiba di Lokasi Muat',
    // TODO: check if can be removed as the definition of ASSIGNED is changed
    // See constants -> DERIVED_PLANNED_TAB_STATUSES
    [SHIPMENT_STATUSES.ASSIGNED]: 'Truk Terpilih',
    [SHIPMENT_STATUSES.CANCELLED_BY_SHIPPER]: 'Dibatalkan Customer',
    [SHIPMENT_STATUSES.CANCELLED_BY_TRANSPORTER]: 'Dibatalkan Vendor',
    [SHIPMENT_STATUSES.CONFIRMATION_EXPIRED]: 'Konfirmasi Terlewat',
    [SHIPMENT_STATUSES.FINISH_LOADING]: 'Selesai Proses Muat',
    [SHIPMENT_STATUSES.FINISH_UNLOADING]: 'Selesai Proses Bongkar',
    [SHIPMENT_STATUSES.NEED_CONFIRMATION]: 'Menunggu Konfirmasi',
    [SHIPMENT_STATUSES.ON_SHIPMENT]: 'Menuju Lokasi Bongkar',
    [SHIPMENT_STATUSES.ONGOING_TO_DESTINATION]: 'Menuju Lokasi Bongkar',
    [SHIPMENT_STATUSES.ONGOING_TO_PICKUP]: 'Menuju Lokasi Muat',
    // TODO: check if can be removed as the definition of PLANNED is changed
    // See constants -> DERIVED_PLANNED_TAB_STATUSES
    [SHIPMENT_STATUSES.SHIPMENT]: 'Dalam Perjalanan',
    [SHIPMENT_STATUSES.LOADING]: 'Muat',
    [SHIPMENT_STATUSES.UNLOADING]: 'Bongkar',
    [SHIPMENT_STATUSES.ONGOING]: 'Berlangsung',
    [SHIPMENT_STATUSES.SCHEDULED]: ' Dijadwalkan',
    [SHIPMENT_STATUSES.PLANNED]: 'Truk Belum Dipilih',
    [SHIPMENT_STATUSES.REASSIGNED_BY_SHIPPER]: 'Customer Mengalihkan Penugasan',
    [SHIPMENT_STATUSES.REJECTED_BY_TRANSPORTER]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.SHIPMENT_COMPLETE]: 'Pengiriman Selesai',
    [SHIPMENT_STATUSES.START_LOADING]: 'Mulai Proses Muat',
    [SHIPMENT_STATUSES.START_UNLOADING]: 'Mulai Proses Bongkar',
    [SHIPMENT_STATUSES.UNFULFILLED]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.UPCOMING]: 'Pengiriman Dibuat',
    [SHIPMENT_STATUSES.VEHICLE_ASSIGNED]: 'Truk Terpilih',
    [SHIPMENT_STATUSES.VEHICLE_NOT_ASSIGNED]: 'Truk Belum Dipilih',
    // TODO: check if can be removed as definition of waiting for confirmation is changed
    // See constants -> DERIVED_ALL_SHIPMENT_TAB_STATUSES
    [SHIPMENT_STATUSES.WAITING_FOR_CONFIRMATION]: 'Menunggu Verifikasi',
    [SHIPMENT_STATUSES.CANCELLED_BY_INTERNAL]: 'Dibatalkan Customer',
    [SHIPMENT_STATUSES['CANCELLED_BY_INTERNAL,CANCELLED_BY_SHIPPER']]: 'Dibatalkan Customer',
    [SHIPMENT_STATUSES.UNFULFILLED_BY_TRANSPORTER]: 'Tidak Terpenuhi',
    [SHIPMENT_STATUSES.CANCELLED_BY_CUSTOMER]: 'Dibatalkan Customer',
    [SHIPMENT_STATUSES.REASSIGNED_BY_CUSTOMER]: 'Customer Mengalihkan Penugasan',
  },
  shipment_still_on_first_disbursement: 'Pengiriman masih pada Pencairan ke-1',
  shipment_summary: 'Ringkasan Kiriman',
  shipment_template: 'Template Pengiriman',
  shipment_type: 'Jenis Pengiriman',
  shipment_fulfillment: 'Jenis Fulfillment',
  shipments_already_on_2nd_disbursement: 'Pengiriman sudah pada Pencairan ke-2',
  shipper: 'Shipper',
  shipper_cancellation_fee: 'Biaya Pembatalan Shipper',
  shipper_fault: 'Kesalahan Shipper',
  shipper_information: 'Informasi Shipper',
  shipper_list: 'Daftar Shipper',
  shipper_name: 'Nama Shipper',
  shipper_name_slash_company: 'Nama Shipper / Perusahaan',
  shipper_overnight_fee: 'Biaya Inap Shipper',
  shipper_payment_date: 'Tanggal Pembayaran Shipper',
  shipper_payment_info: 'Info Pembayaran Shipper',
  shipper_payment_reminders: '{{reminder}}',
  shipper_price: 'Harga Pengiriman',
  shipper_remarks: 'Catatan',
  shipper_status: 'Status Pengirim',
  shipper_status_map: {
    [GENERAL_ENTITY_STATUSES.DEACTIVATED]: 'Nonaktif',
    [GENERAL_ENTITY_STATUSES.REGISTERED]: 'Aktif',
    [SHIPPER_STATUSES.ACTIVE]: 'Aktif',
    [SHIPPER_STATUSES.CHURN]: 'Churn',
    [SHIPPER_STATUSES.INACTIVE]: 'Tidak aktif',
    [SHIPPER_STATUSES.PENDING_ONBOARDING]: 'Orientasi Tertunda',
    [SHIPPER_STATUSES.REJECTED]: 'Ditolak',
    [SHIPPER_STATUSES.SUSPENDED]: 'Tergantung',
  },
  shipper_type: 'Jenis Pengirim',
  shipper_types_map: {
    [SHIPPER_TYPES.A1]: 'A1',
    [SHIPPER_TYPES.A2]: 'A2',
    [SHIPPER_TYPES.A3]: 'A3',
  },
  shipping_seal_number: 'Nomor Segel Pelayaran',
  show: 'Tampilkan',
  show_claims_in: 'Tunjukkan Klaim dalam',
  show_claims_in_map: {
    [SHOW_CLAIMS_IN.BERITA_ACARA]: 'Berita Acara',
    [SHOW_CLAIMS_IN.LOAD_ITEMS]: 'Load Items',
    [SHOW_CLAIMS_IN.POD]: 'POD',
  },
  show_everything: 'Tampilkan Semuanya',
  show_less: 'Lihat Lebih Sedikit',
  show_more: 'Lihat Selengkapnya',
  show_qr_code_truck: 'Lihat Kode QR Truk',
  signature_name: 'Nama Tertanda',
  signature_optional: 'Tanda Tangan dan Meterai (opsional)',
  signature_optional_pbi: 'Tanda Tangan Digital (opsional)',
  signature_required_pbi: 'Tanda Tangan Digital',
  signature_title: 'Jabatan',
  signed: 'Mengetahui',
  sim: 'SIM',
  skip_this_section: 'Lewati bagian ini',
  skip: 'Nanti Saja',
  sku: 'SKU',
  slot_amount: 'Jumlah Slot',
  sort: 'Urutkan',
  sort_column: 'Urutkan Kolom',
  sort_label: 'Urut berdasarkan',
  sort_table: 'Urutkan Tabel',
  sort_table_coach_mark: {
    [COACH_MARK_CONTENT.DESCRIPTION]: 'Atur urutan data pada tabel berdasarkan teks (A ke Z) atau angka (terkecil ke terbesar)',
    [COACH_MARK_CONTENT.TITLE]: 'Urutkan Isi Tabel',
  },
  special_day_inclusion_type: {
    NATIONAL_HOLIDAYS: 'Hari Libur Nasional',
    SUNDAYS: 'Hari Minggu',
  },
  sppkp: 'SPPKP',
  start: 'Mulai',
  start_date: 'Tanggal Mulai',
  start_loading_date: 'Tanggal Mulai Muat',
  start_time: 'Waktu Mulai',
  status: 'Status',
  status_change_timestamp: 'Waktu Perubahan Status',
  status_log: 'Status Log',
  stnk: 'STNK',
  stop_duration: 'Lama Berhenti',
  store: 'Store',
  subject: 'Subject',
  subject_placeholder: 'Ketik subjek/judul email. Contoh: “Invoice Pengiriman DO-123”',
  submission_summary: 'Ringkasan Pengajuan',
  submission_type: 'Tipe',
  submissions_accepted: 'Pengiriman Diterima',
  submissions_status: 'Status Pengajuan',
  submissions_submitted: 'Pengiriman Diajukan',
  submit_disbursement_details: 'Kirim Detail Pencairan',
  submit_leave: 'Ya, tinggalkan',
  submit_receipt_results: 'Kirim Hasil Penerimaan',
  submit_review_results: 'Kirim Hasil Review',
  submitted_bid: 'Submitted Bid',
  submitted_date: 'Tanggal Diajukan',
  submitted_payment: 'Pendanaan yang Diajukan',
  submit_date: 'Tanggal Pengajuan',
  subtotal: 'Subtotal',
  suggestion: 'Saran',
  summary: 'Ringkasan',
  summary_fee: 'Ringkasan Tagihan',
  swift_code: 'Swift Code',
  swift_payment: 'Pembayaran Cepat',
  swift_payment_empty_state: {
    button: 'Daftar Sekarang',
    description: 'Daftar dan tambahkan data customer untuk kebutuhan pendanaan operasional armada pengiriman Anda.',
    title: 'Belum Terdaftar',
  },
  swift_payment_price_adjustment_type_map: {
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.CLAIM]: 'Claim',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.OTHERS]: 'Lainnya',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.ADDITION_IN_DO_PRICE]: 'Penambahan Harga DO',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.REDUCE_IN_DO_PRICE]: 'Pengurangan Harga DO',
    [SWIFT_PAYMENT_PRICE_ADJUSTMENT_TYPE.TRANSFER_FEE]: 'Transfer Fee',
  },
  swift_payment_request_status_map: {
    [SWIFT_PAYMENT_REQUEST_STATUSES.APPROVED]: 'Pencairan ke-1 Diproses',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DISBURSED]: 'Pencairan ke-1 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.DOF2_PROCESSED_PARTIALLY]: 'Pencairan ke-2 Diajukan sebagian',
    [SWIFT_PAYMENT_REQUEST_STATUSES.ON_HOLD]: 'Pengajuan Ditunda',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REJECTED]: 'Ditolak',
    [SWIFT_PAYMENT_REQUEST_STATUSES.REQUESTED]: 'Diajukan',
    [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_DISBURSED]: 'Pencairan ke-2 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.SECOND_DISBURSEMENT_IN_PROCESS]: 'Pencairan ke-2 Diproses',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_DISBURSED]: 'Pencairan ke-3 Ditransfer',
    [SWIFT_PAYMENT_REQUEST_STATUSES.THIRD_DISBURSEMENT_IN_PROCESS]: 'Pencairan ke-3 Diproses',
  },
  swift_payment_section: {
    [SWIFT_PAYMENT_TYPES.DOF]: 'DOF',
    [SWIFT_PAYMENT_TYPES.DOF2]: 'DOF Pencairan ke-2',
    [SWIFT_PAYMENT_TYPES.PODF]: 'PODF',
  },
  tags: 'Tag',
  take_shipment: 'Ambil Pengiriman {{doNumber}}',
  target_utlisation_warning: 'Anda perlu mengatur target utilisasi untuk rute di atas agar ketersediaan armada dapat diprediksi.',
  tax_details: 'Rincian Pajak',
  tax_document: 'Faktur Pajak',
  tax_invoice: 'Faktur Pajak',
  tax_invoice_number: 'Nomor Faktur Pajak',
  tax_road: 'Lembar Pajak',
  tax_types_of_payment_request_map: {
    vat: 'PPN',
    wht23: 'PPh23',
  },
  telephone_no: 'No. Telepon',
  template: 'Template',
  template_invoice: 'Template Invoice',
  template_name: 'Nama Template',
  terms_and_conditions: {
    agreed: 'Saya telah membaca dan menyetujui',
    agreement: 'syarat dan ketentuan',
    already_agreed: 'Saya telah membaca dan menyetujui syarat dan ketentuan',
  },
  terms_of_payments: 'Ketentuan Pembayaran (TOP)',
  terms_of_payments_in_days: 'Jangka Waktu',
  terms_of_payment_due_date: 'Tgl. Jatuh Tempo',
  terms_of_payments_due_date: 'Tgl Jatuh Tempo TOP',
  things: 'hal',
  third_defrost: 'Ketiga',
  third_disbursement: 'Pencairan Ketiga',
  this_month: 'Bulan Ini',
  this_year: 'Tahun Ini',
  tila: 'TILA',
  time_to_check_proforma_invoice: 'Waktu Pengecekan Proforma Invoice',
  invoice_performance_tracking_time: 'Waktu Pelacakan Performa Invoice',
  timestamp_reference: 'Referensi Waktu',
  tin: 'NPWP',
  to: 'Kepada',
  to_be_attached_in_invoice: 'Untuk dilampirkan dalam Faktur',
  to_be_returned_to_shipper: 'Untuk dikembalikan ke pengirim',
  tonnage: 'Tonase',
  tonnage_or_cubication: 'Tonase / Kubikasi',
  total: 'Jumlah',
  total_en: 'Total',
  total_additional_fee: 'Total Biaya Tambahan',
  total_adjustment: 'Total Adjustment',
  total_adjustments: 'Total Adjustment',
  total_amount: 'Jumlah Total',
  total_approved_request: 'Total Pengajuan Disetujui',
  total_approved_shipment: 'Total Pengiriman Disetujui',
  total_balance: 'Total Saldo',
  total_charges: 'Total Tagihan',
  total_cost: 'Total Biaya',
  total_disbursement: 'Total Pencairan',
  total_fee: 'Total Biaya',
  total_first_disbursement: 'Total Pencairan Pertama',
  total_first_disbursement_approved: 'Total Pencairan Pertama Disetujui',
  total_first_disbursement_formula: 'Pencairan Pertama - Kargo Fee - VAT',
  total_invoice: 'Jumlah Invoice',
  total_payment: 'Total Pembayaran',
  total_payment_by_shipper: 'Jumlah Pembayaran Shipper',
  total_price: 'Total Biaya',
  total_price_with_tax_item: 'Total Harga dengan Pajak',
  total_second_disbursement: 'Total Pencairan Kedua',
  total_second_disbursement_approved: 'Total Pencairan Kedua Disetujui',
  total_shipment: 'Total Pengiriman',
  total_submission: 'Total Pengajuan',
  total_swift_payment_submission: 'Total Pengajuan Pembayaran Cepat',
  total_third_disbursement_approved: 'Total Pencairan Ketiga Disetujui',
  total_utilization: 'Total Utilisasi',
  trace_history: 'Riwayat Perjalanan',
  tracking_point_url: 'Tautan Pelacakan',
  tracking_point_url_placeholder: 'Masukkan tautan',
  transaction_type: 'Jenis Transaksi',
  transport_overnight_fee: 'Biaya Inap Transporter',
  transporter: 'Transporter',
  transporter_cancellation_fee: 'Biaya Pembatalan Transporter',
  transporter_incentive_amount_type: {
    [TRANSPORTER_INCENTIVE_AMOUNT_TYPE.CREDIT]: 'Penyesuaian Insentif',
    [TRANSPORTER_INCENTIVE_AMOUNT_TYPE.DEBIT]: 'Penambahan Insentif',
    [TRANSPORTER_INCENTIVE_AMOUNT_TYPE.DISBURSEMENT]: 'Pencairan Insentif',
  },
  transporter_incentive_business_line: {
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.ENTERPRISE_MID_MILE]: 'Enterprise Mid-Mile',
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.ENTERPRISE_REFERRAL]: 'Enterprise Referral',
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.SME_MID_MILE]: 'SME Mid-Mile',
    [TRANSPORTER_INCENTIVE_BUSINESS_LINE.SME_REFERRAL]: 'SME Referral',
  },
  transporter_list: 'Daftar Transporter',
  transporter_name: 'Nama Transporter',
  transporter_notes: 'Catatan untuk vendor',
  transporter_notes_bidding: 'Catatan untuk Transporter',
  travel_details: 'Rincian Surat Jalan',
  truck: 'Truk',
  truck_body: 'Badan Truk',
  truck_documents: 'Dokumen Truk',
  truck_and_driver: 'Truk & Supir',
  truck_and_driver_detail: 'Detail Truk dan Supir',
  truck_doc_req: 'Ketentuan Dokumen Truk',
  truck_fee: {
    [PAYMENT_TYPES.ADDITIONAL_FEE]: 'Biaya Tambahan',
    [PAYMENT_TYPES.SHIPMENT_FEE]: 'Biaya Pengiriman',
    [PAYMENT_TYPES.TOTAL_FEE]: 'Total Biaya',
  },
  truck_head: 'Kepala Truk',
  truck_not_found: 'Truk tidak ditemukan',
  truck_qr_code: 'Kode QR Truk',
  truck_request: 'Pesanan',
  truck_required: 'Truk Yang Diperlukan',
  truck_requirements: 'Persyaratan truk',
  truck_requirement_kir_option: {
    ACTIVE_KIR: 'KIR Aktif',
    EXPIRED_KIR: 'KIR mati',
    EXPIRED_PROOF_OF_RENEWAL: 'Bukti pembaharuan mati',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    VALID_PROOF_OF_RENEWAL: 'Bukti pembaharuan aktif',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  truck_requirement_lembar_pajak_option: {
    ACTIVE_LEMBAR_PAJAK: 'Lembar Pajak Aktif',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    PROOF_OF_DOCUMENTS_OR_LICENSE_RENEWAL: 'Bukti pembaharuan aktif',
    PROOF_OF_EXPIRED_TAX: 'Bukti Pajak mati',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  truck_requirement_sim_option: {
    ACTIVE_SIM: 'SIM Aktif',
    EXPIRED_SIM: 'SIM mati',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    PHOTOCOPY_OF_SIM: 'Fotokopi SIM',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  truck_requirement_stnk_option: {
    ACTIVE_STNK: 'STNK Aktif',
    EXPIRED_STNK: 'STNK mati',
    EXPIRED_TRAFFIC_TICKET: 'Surat tilang mati',
    TRAFFIC_TICKET_PHOTOCOPY: 'Fotokopi Tiket tilang',
    VALID_PROOF_OF_RENEWAL: 'Bukti pembaharuan aktif',
    VALID_TRAFFIC_TICKET: 'Surat tilang yang berlaku',
  },
  truck_safety_req: 'Persyaratan Keamanan Truk',
  truck_type: 'Jenis Truk',
  type: 'Jenis',
  type_vehicle: 'Tipe Armada',
  type_of_access: 'Jenis Akses',
  type_of_company: 'Jenis Perusahaan',
  type_of_product: 'Tipe produk',
  type_to_search_driver: 'Ketik 1 huruf untuk mencari supir',
  type_to_search_license_plate: 'Ketik 1 huruf untuk mencari nomor polisi',
  unit: 'Unit',
  unit_list: 'Daftar Unit',
  unload_date_and_time: 'Tanggal & Waktu Bongkar',
  unload_date: 'Tanggal Bongkar',
  unload_point: 'Bongkar {{pointName}}',
  unload_schedule: 'Jadwal Bongkar',
  unload_time: 'Waktu Bongkar',
  unload_location: 'Lokasi Bongkar',
  unload: 'Bongkar',
  unloading_completion_date: 'Tanggal Selesai Bongkar',
  unloading_date: 'Tanggal Bongkar',
  unloading_fee: 'Biaya Bongkar',
  unloading_info: 'Bongkar Informasi',
  unloading_time: 'Waktu Bongkar',
  upcoming_shipment: 'Pengiriman Selanjutnya',
  updated: 'Diperbaharui',
  updated_date: 'Tanggal Diubah',
  update_container_info: 'Ubah Informasi Container',
  upload: 'Unggah',
  upload_attachment: 'Unggah Lampiran',
  upload_csv: 'Unggah CSV',
  upload_document: 'Unggah Dokumen',
  upload_do_block_dialog_description: 'Hubungi pihak Kargo Nexus for Transporters untuk meningkatkan jumlah kuota DO Anda',
  upload_do_block_dialog_title: 'Jumlah DO Telah Melebihi Kuota Maksimal',
  upload_do_block_grace_period_dialog_description: 'Anda tidak dapat mengakses fitur ini karena periode kontrak Anda telah habis. Hubungi kami agar tetap terhubung dengan seluruh fitur Kargo Nexus for Transporters',
  upload_do_block_grace_period_dialog_title: 'Hubungi kami untuk menggunakan fitur ini',
  upload_driving_licence: 'Unggah SIM',
  upload_file: 'Upload File',
  upload_kir: 'Unggah KIR',
  upload_location_fee: 'Biaya Unggah Lokasi',
  upload_message: 'Untuk mengunggah file ini, Andupdatea harus mengubah',
  upload_npwp: 'Unggah NPWP',
  upload_pod: 'Unggah POD',
  upload_receipt: 'Unggah Tanda Terima',
  upload_sppkp: 'Unggah SPPKP',
  upload_stnk: 'Unggah STNK',
  upload_tax_document: 'Unggah Faktur Pajak',
  upload_the_id_card: 'Unggah KTP',
  upload_tila: 'Unggah TILA',
  uploaded: 'Diunggah',
  uploaded_from: 'Diunggah dari',
  use: 'Gunakan',
  use_format: 'Gunakan Format',
  use_template: 'Gunakan Template',
  use_for_timestamp: 'Gunakan sebagai penanda waktu',
  use_goods_capacity: 'Use Goods Capacity',
  used_date: 'Tanggal terpakai',
  user: 'User',
  user_access_type_map: {
    [FIRST_MILE_USER_ACCESS_TYPE.CONSIGNEE]: 'Consignee',
    [FIRST_MILE_USER_ACCESS_TYPE.FREIGHT_FORWARDER]: 'Freight Forwarder',
    [PANTHERA_PIC_DIVISION.CANVASSER]: 'Canvasser',
    [USER_ACCESS_TYPE.ADMIN]: 'Admin',
    [USER_ACCESS_TYPE.FINANCE]: 'Akses Finansial',
    [USER_ACCESS_TYPE.FULL_ACCESS]: 'Akses Tidak Terbatas',
    [USER_ACCESS_TYPE.RESTRICTED_ACCESS]: 'Akses Terbatas',
    [USER_ACCESS_TYPE.PRICE_RESTRICTED_ACCESS]: 'Akses Tanpa Harga',
  },
  user_status_map: {
    [USER_STATUSES.ACTIVE]: 'Aktif',
    [USER_STATUSES.INACTIVE]: 'Nonaktif',
    [USER_STATUSES.REGISTERED]: 'Belum Aktif',
  },
  utilization: 'Utilisasi',
  utilization_csv_upload_header: 'Unggah Rute dari Dokumen CSV',
  utilization_tab: {
    add_route: 'Tambah Rute',
    add_target_lead_time_per_route: 'Tambah Target Lead Time Per Rute',
    add_title: 'Tambah Target Utilisasi Per Rute',
    company_working_day: 'Hari Kerja Perusahaan',
    change: 'Edit Rute',
    edit_target_lead_time_per_route: 'Ubah Target Lead Time Per Rute',
    edit_title: 'Ubah Target Utilisasi Per Rute',
    header_title: 'Total Target Utilisasi',
    search_placeholder: 'Cari nama gudang...',
    select_placeholder: 'Pilih',
    success: 'Target utilisasi baru berhasil ditambahkan',
    target_lead_time_per_route: 'Target Lead Time Per Rute',
    target_lead_time: 'Target Lead Time',
    truck_type: 'Tipe Armada',
    unit: 'Per Bulan',
    utilization_target: 'Target Utilisasi',
  },
  valid_until: 'Berlaku Hingga',
  variant: 'Varian',
  vat_kargo_fee: 'VAT Kargo Fee ',
  vehicle: 'Armada',
  vehicle_availablity: 'Ketersediaan Armada',
  vehicle_availablity_status_map: {
    [VEHICLE_AVAILABILITY_STATUS.AVAILABLE]: 'Semua Tanggal Tersedia',
    [VEHICLE_AVAILABILITY_STATUS.PARTIALLY_AVAILABLE]: 'Sebagian Tanggal Tersedia',
    [VEHICLE_AVAILABILITY_STATUS.UNKNOWN]: 'Atur target utilisasi',
    [VEHICLE_AVAILABILITY_STATUS.UNAVAILABLE]: 'Tidak Tersedia',
  },
  vehicle_condition: 'Kondisi Armada',
  vehicle_body_type: 'Karoseri',
  vehicle_is_damaged: 'Armada Sedang Bermasalah',
  vehicle_registration: 'Lisensi Armada',
  vehicle_status_map: {
    ACTIVE: 'Aktif',
    DEACTIVATED: 'Dinonaktifkan',
    DRAFT: 'Draft',
    EXPIRED: 'Expired',
    PENDING: 'Ditunda',
    REGISTERED: 'Terdaftar',
    REJECTED: 'Ditolak',
  },
  vehicle_tracking: 'Memantau Armada',
  vehicle_type: 'Jenis Truk',
  vehicles: 'Armada',
  vendor: 'Vendor',
  vendor_registration_id: 'ID Pendaftaran Vendor',
  verification_code: 'Kode Verifikasi',
  verified_user: 'Pengguna terverifikasi',
  version: 'Versi',
  via_qr_code: 'via kode QR',
  view: 'Lihat',
  view_changes: 'Lihat Perubahan',
  view_delivery: 'Lihat Pengiriman',
  view_pod_pol_details: 'Lihat SJ',
  view_notes: 'Lihat Catatan',
  view_template: 'Lihat Template',
  waiting_for_admin_approval: 'Menunggu Persetujuan Admin',
  waiting_for_admin_calculation: 'Menunggu Perhitungan Admin',
  waiting_for_pod: 'POD belum diterima PBI',
  waiting_verification: 'Menunggu Verifikasi',
  warehouse: 'Gudang',
  warehouse_information: 'Informasi Gudang',
  warehouse_name: 'Nama Gudang',
  week: 'Minggu',
  weight_volume: 'Volume / Berat',
  whatsapp: 'Whatsapp',
  working_days: 'Hari kerja',
  wps_seal_number: 'Nomor Segel Customer',
  write: 'Tulis',
  write_customer_code: 'Tulis Kode Customer...',
  wrong_email_format: 'Mohon ketik alamat email yang valid.',
  wrong_goods: 'Salah Kirim barang',
  x_capital: 'X',
  ya_cancel: 'Ya, batalkan',
  year_setup: 'Pengaturan Tahun',
  year_of_manufacturing: 'Tahun Produksi',
  yes: 'Ya',
  yes_add: 'Ya, Tambah',
  yes_confirmation: 'Ya, Konfirmasi',
  yes_delete: 'Ya, hapus',
  yes_fulfilled: 'Ya, Memenuhi',
  yes_save_and_exit: 'Ya, Simpan dan Keluar',
  yes_submit: 'Ya, Ajukan',
  delete_attachment: 'Hapus Lampiran',
  mid_mile: 'Mid Mile',
  first_mile: 'First Mile',
  container_no_table: 'No. Container',
  delivery_order_search_type_map: {
    [DELIVERY_ORDER_SEARCH_TYPES.DO_NUMBER]: 'Nomor Pengiriman',
    [DELIVERY_ORDER_SEARCH_TYPES.LICENSE_PLATE]: 'Nomor Polisi',
    [DELIVERY_ORDER_SEARCH_TYPES.CONTAINER_NUMBER]: 'Nomor Container',
  },
  input_search: 'Tulis Pencarian',
  revise_invoice: 'Revisi Invoice',
  pbi_container_number: 'Nomor Kontainer',
  wet_signed_invoice: 'Invoice TTD Basah',
  total_income: 'Total Penghasilan',
  tax_base: 'Dasar Pengenaan Pajak',
  income_tax: 'Penghasilan Kena Pajak',
  delivery_price: 'Harga Pengiriman',
  ppn_percentage: 'Persentase PPN',
  ppn_type_map: {
    [PPN_TYPE.INCLUDE]: 'Termasuk Pajak',
    [PPN_TYPE.EXCLUDE]: 'Tidak Termasuk Pajak',
  },
  ppn_full_text: 'Pajak Pertambahan Nilai (PPN)',
  total_tax: 'Total Pajak',
  invoice_date_card: 'Tgl. Invoice',
  wallet: 'Wallet',
  expiry_date: 'Tanggal Kadaluwarsa',
  driver_license_expiry_date: 'Tanggal Kadaluwarsa',

  saldo_kargo: {
    your_wallet: 'Kantong Anda',
    transfer_now: 'Transfer Sekarang',

    transaction_processed: 'Transaksi Diproses',
    estimated_entry: 'Estimasi masuk rekening',
    transaction_notice: 'Beberapa transaksi perlu beberapa waktu. Akan ada notifikasi jika sukses.',

    transaction_success: 'Transaksi Berhasil',
    all_transaction: 'Semua Transaksi',
    incoming_transaction: 'Transaksi Masuk',
    outbound_transaction: 'Transaksi Keluar',
    show_more: 'Tampilkan Lebih',

    today: 'Hari Ini',
    days_left: 'hari lagi',
    sending_to_account: 'Sedang Mengirim ke Rekening',
    received_in_wallet: 'Diterima di Kantong',
    send_to_account: 'Dikirim ke Rekening',
    received_in_wallet_cancel: 'Diterima di Kantong (Dibatalkan)',
    send_to_account_cancel: 'Dikirim ke Rekening (Dibatalkan)',
    send_two_account: 'Dikirim 2 Akun',

    weekly_disbursement_warning_modal: {
      process_transfer_wallet: 'Proses Transfer Kantong',
      process_transfer_seven_days: 'Proses Transfer 7 Hari',
      withdrawal_to_account_maximum_time: 'Proses pencairan ke rekening anda membutuhkan waktu selambat-lambatnya 7-15 hari kerja.',
      transfer_every_thursday: 'Transfer setiap Kamis',
      transfer_request_before_thursday_notice: 'Permintaan Transfer yang diterima oleh {{entityName}} sebelum hari Kamis 17:00 WIB akan masuk ke rekening Anda paling lambat pada hari Kamis berikutnya.',
      transfer_by_partner: 'Transfer dilakukan Rekan',
      transaction_service_notice: '{{entityName}} berkolaborasi dengan beberapa akun penyedia jasa layanan transaksi. Sumber transfer dana akan tercantum pada detail transaksi.',
      im_understand: 'Saya Mengerti',
    },
  },

  saldo_kargo_withdrawal: {
    direct_disbursement: 'Cair Langsung',
    days: '{{days}} Hari',
    today: 'Hari Ini',
    disbursement_in_days: 'Cair dalam {{days}}',
    received_under_five_minutes: 'Masuk Rek. dibawah 5 Menit',
    received_account_date: 'Masuk Rek. {{date}}',
    fast_transfer_deduction: 'Potongan transfer cepat • {{amount}}',
    calculated_from_total_transaction_amount: 'Terhitung dari semua Jumlah Transaksi.',
    free_transfer_deduction: 'Bebas Potongan Transfer! • {{free}}',
    confirm_transfer: 'Konfirmasi Transfer',
    hide_detail: 'Sembunyikan Detail',
    show_detail: 'Tampilkan Detail',
    total_transfer: 'Total Transfer',
    transfer_now: 'Transfer Sekarang',
    to: 'Ke',

    instant_disbursement_info_modal: {
      process_fast_transfer: 'Process Transfer Cepat',
      processing_time_under_five_minutes: 'Waktu Process Dibawah 5 Menit',
      received_in_five_minutes: 'Tanpa harus menunggu lama, pendapatan Anda langsung masuk ke rekening dalam 5 menit.',
      transfer_deduction_according_contract: 'Potongan Transfer sesuai Kontrak',
      amount_deduction_notice: 'Dengan melakukan transfer cepat, jumlah yang diterima sudah dikurangi persentase potongan dari pembayaran pertama pengiriman Anda. Potongan tersebut terhitung dari persentase yang telah disetujui di kontrak.',
      im_understand: 'Saya Mengerti',
    },

    thursday_disbursement_info_modal: {
      process_transfer_in_days: 'Proses Transfer dalam {{days}} Hari',
      transfer_every_thursday: 'Transfer setiap Kamis',
      transfer_request_notice: '{{entityName}} mengumpulkan semua permintaan transfer yang dilakukan sebelum Kamis, jam 17:00 setiap minggunya dan langsung masuk ke rekening anda dalam 5 menit.',
      transfer_by_partner: 'Transfer dilakukan Rekan',
      transaction_service_notice: '{{entityName}} berkolaborasi dengan beberapa akun penyedia jasa layanan transaksi. Sumber transfer dana akan tercantum pada detail transaksi.',
      im_understand: 'Saya Mengerti',
    },

    weekly_disbursement_info_modal: {
      process_transfer_in_days: 'Proses Transfer dalam {{days}} Hari',
      process_transfer_seven_days: 'Proses Transfer 7 Hari',
      disbursement_process_notice: 'Proses pencairan ke rekening anda membutuhkan waktu selambat-lambatnya 7-15 hari kerja.',
      transfer_every_thursday: 'Transfer setiap Kamis',
      transfer_request_notice: '{{entityName}} mengumpulkan semua permintaan transfer yang dilakukan sebelum Kamis 17:00 dan masuk ke rekening Anda hari Kamis minggu berikutnya.',
      transfer_by: 'Transfer dilakukan',
      transaction_service_notice: '{{entityName}} berkolaborasi dengan beberapa akun penyedia jasa layanan transaksi. Sumber transfer dana akan tercantum pada detail transaksi.',
      im_understand: 'Saya Mengerti',
    },

    withdrawal_process_modal: {
      transfer_processed: 'Transfer Diproses',
      total: 'Total',
      close: 'Tutup',
    },
  },

  saldo_kargo_withdrawal_status: {
    transaction_proof_success: 'Bukti Transaksi Berhasil',
    transaction_proof: 'Bukti Transaksi',

    additional_and_shipment_fee: 'Biaya Tambahan & Pengiriman',
    shipment_fee: 'Biaya Pengiriman',
    additional_fee: 'Biaya Tambahan',
    shipment_date: 'Pengiriman {{date}}',

    fast_transfer_deduction: 'Potongan Transfer Cepat',
    starting_from_first_payment: 'Terhitung Dari Pembayaran Pertama',
    e_stamp: 'e-Materai',

    recipient: 'Penerima',
    recipient_account: 'Akun Penerima',
    recipient_bank: 'Bank Penerima',
    account_number: 'Nomor Rekening',
    sender: 'Pengirim',
    sender_account: 'Akun Pengirim',
    amount: 'Jumlah',
    represent: 'Mewakili',
    transaction_id: 'ID Transaksi',
    invoice: 'Invoice',
    code: 'Kode',
    hide_cost_detail: 'Sembunyikan Detail Biaya',
    show_cost_detail: 'Lihat Detail Biaya',
    adjustment_fee: 'Adjustment Fee',
    load_more: 'Load More',
    download_transaction_proof: 'Unduh Bukti Transaksi',

    cancellation: 'Gagal Masuk Kantong pada',
    money_out: 'Berhasil ditransfer pada',
    money_in: 'Diterima di Kantong pada',
  },
};
